<head>
<!--  &lt;!&ndash; Global site tag (gtag.js) - Google Ads: 10837993304 &ndash;&gt; <script async src="https://www.googletagmanager.com/gtag/js?id=AW-10837993304"></script> <script> window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-10837993304'); </script>-->
</head>
<section class="space-container">
<section *ngIf="!showProfile" class="login-section">
  <section class="container">
    <section class="row justify-content-center justify-content-lg-between">
      <section class="window col-12 col-lg-6">
        <section class="window-header">
          <p class="window-title">{{"LOGIN.LOGIN" | translate}}</p>
        </section>

        <section class="window-top">
          <form [formGroup]="loginForm">
            <p class="input-span">{{"LOGIN.EMAIL" | translate}}</p>
            <input class="input-field" type="email" id="email" name="email" email
                   formControlName="email" placeholder="{{'LOGIN.EMAIL_PLACEHOLDER' | translate}}" required>
            <p class="input-span">{{"LOGIN.PASSWORD" | translate}}</p>
            <input class="input-field" type="password" id="password" name="password"
                   formControlName="password" placeholder="{{'LOGIN.PASSWORD_PLACEHOLDER' | translate}}" required>
            <br>
            <section class="row button-wrapper">
              <button *ngIf="loginForm.status === 'VALID'" (click)="login()"
                      class="button login-button col-12">{{"LOGIN.LOGIN_SMALLCAPS" | translate}}</button>
              <button *ngIf="loginForm.status === 'INVALID'"
                      class="button login-button disabled-button col-12">{{"LOGIN.LOGIN_SMALLCAPS" | translate}}</button>
              <p [ngClass]="{'disabled-pass-reset': btnDisabled === true}" class="underlined col-12" (click)="forgot()">{{"LOGIN.FORGOT" | translate}}</p>
            </section>
          </form>
        </section>

        <section class="thin-line"></section>

        <section class="window-bottom">
          <p class="no-account">{{"LOGIN.NO_ACCOUNT" | translate}}</p>

          <p class="underlined" routerLink="/registration">{{"LOGIN.REGISTER" | translate}}</p>
        </section>
      </section>

      <section class="fancy-image-section col-12 col-lg-6">
        <img class="login-image" src="assets/images/login_images_group.png" alt="">
      </section>
    </section>
  </section>
</section>

<section *ngIf="showProfile" class="profile-section">
  <section class="container">
    <p class="contact-title">{{'PROFILE.WELCOME' | translate}} {{personalDataForm.controls.firstname.value}}</p>
    <p class="contact-text">{{'PROFILE.MORE_WELCOME' | translate}}</p>

    <section class="number-01-container">
      <form [formGroup]="personalDataForm">
        <section class="row">
          <section class="col-lg-9 col-xl-8">
            <section class="personal-data-container row">
              <div class="col-12">
                <p class="title-medium">{{'PAYMENT.INFO_ABOUT_YOU' | translate}}</p>
              </div>

              <div class="col-12 col-sm-6">
                <p class="profile-input-span">{{'PAYMENT.SURNAME' | translate}}</p>
                <input [ngClass]="{'input-field-disabled': editFields}" class="input-field" formControlName="lastname"
                       type="text"
                       placeholder="{{'PAYMENT.SURNAME_PLACEHOLDER' | translate}}">
              </div>

              <div class="col-12 col-sm-6">
                <p class="profile-input-span">{{'PAYMENT.FIRSTNAME' | translate}}</p>
                <input [ngClass]="{'input-field-disabled': editFields}" class="input-field" formControlName="firstname"
                       type="text"
                       placeholder="{{'PAYMENT.FIRSTNAME_PLACEHOLDER' | translate}}">
              </div>

              <div class="col-12 col-sm-6">
                <p class="profile-input-span">{{'PAYMENT.PHONE' | translate}}</p>
                <input [ngClass]="{'input-field-disabled': editFields}" class="input-field" formControlName="phone"
                       type="text"
                       placeholder="{{'PAYMENT.PHONE_PLACEHOLDER' | translate}}">
              </div>
            </section>

            <section class="shipping-address-container row">
              <div class="col-12">
                <p class="title-medium">{{'PAYMENT.ADDRESS' | translate}}</p>
              </div>
              <div class="col-12 col-sm-3 col-lg-2">
                <p class="profile-input-span">{{'PAYMENT.ZIPCODE' | translate}}</p>
                <input [ngClass]="{'input-field-disabled': editFields}" formControlName="irszam" class="input-field"
                       type="text"
                       placeholder="{{'PAYMENT.ZIPCODE_PLACEHOLDER' | translate}}">
              </div>

              <div class="col-12 col-sm-9 col-lg-4">
                <p class="profile-input-span">{{'PAYMENT.CITY' | translate}}</p>
                <input [ngClass]="{'input-field-disabled': editFields}" formControlName="city" class="input-field"
                       type="text"
                       placeholder="{{'PAYMENT.CITY_PLACEHOLDER' | translate}}">
              </div>

              <div class="col-12 col-sm-9 col-lg-4">
                <p class="profile-input-span">{{'PAYMENT.PUBLIC_PLACE_TYPE' | translate}}</p>
                <input [ngClass]="{'input-field-disabled': editFields}" formControlName="cim" class="input-field"
                       type="text"
                       placeholder="{{'PAYMENT.PUBLIC_PLACE_TYPE_PLACEHOLDER' | translate}}">
              </div>

              <div class="col-12 col-sm-3 col-lg-2">
                <p class="profile-input-span">{{'PAYMENT.ADDRESS_NUMBER' | translate}}</p>
                <input [ngClass]="{'input-field-disabled': editFields}" formControlName="hazszam" class="input-field"
                       type="text"
                       placeholder="{{'PAYMENT.ADDRESS_NUMBER_PLACEHOLDER' | translate}}">
              </div>

              <div class="col-12 col-sm-5 col-lg-4">
                <p class="profile-input-span">{{'PAYMENT.OTHER' | translate}}</p>
                <input [ngClass]="{'input-field-disabled': editFields}" formControlName="address_2" class="input-field"
                       type="text"
                       placeholder="{{'PAYMENT.OTHER_PLACEHOLDER' | translate}}">
              </div>
            </section>
          </section>

          <section class="button-container container">

            <button class="button" (click)="editProfileData()">{{'BUTTON.SAVE' | translate}}</button>
            <button class="button" (click)="editFields = !editFields">{{'BUTTON.EDIT' | translate}}</button>
            <button class="button"><span (click)="logout()" routerLink="/">{{'PROFILE.LOGOUT' | translate}}</span></button>
          </section>
        </section>
      </form>
      <section class="col-lg-3 col-xl-4"></section>
    </section>
  </section>

  <section *ngIf="orders.length !== 0" class="thin-line wide-thin-line"></section>

  <section *ngIf="orders.length !== 0" class="recent-buys-container container">
    <p class="contact-title recent-buys">{{'PROFILE.RECENT_BUYS' | translate}}</p>

    <section class="recent-buy-wrapper" *ngFor="let order of orders">
      <section class="container">
        <section class="order row">
          <p class="order-id-text col-8">{{'PROFILE.ORDER_ID' | translate}} {{order.order_id}}</p>
          <p class="date-text col-4">{{order.date_added}}</p>

          <section class="thin-line wide-thin-line"></section>

          <p class="header col-6">{{'PROFILE.PRODUCT_NAME' | translate}}</p>
          <p class="header col-3">{{'PROFILE.QUANTITIY' | translate}}</p>
          <p class="header col-3">{{'PROFILE.PRICE' | translate}}</p>

          <section class="container">
            <section class="row" *ngFor="let product of order.products">
              <p class="table-text col-6">{{product.name}}</p>
              <p class="table-text col-3">{{product.quantity}} {{'PROFILE.PCS' | translate}}</p>
              <p class="table-text col-3">{{product.total}}</p>
            </section>
          </section>

          <p class="total col-8">{{'PROFILE.TOTAL' | translate}}</p>
          <p
            class="total-price col-4">{{roundTotal(order.total).toLocaleString()}} {{'CURRENCY.' + order.currency_code | translate}}</p>

          <section class="thin-line wide-thin-line"></section>

          <p class="header col-12">{{'PROFILE.ADDRESS' | translate}}</p>
          <p class="table-text col-12">{{order.shipping_address_1}}</p>

          <p class="header col-12">{{'PROFILE.OTHER' | translate}}</p>
          <p class="table-text col-12">{{order.shipping_address_2}}</p>

          <p class="header col-12">{{'PROFILE.TO_CURRIER' | translate}}</p>
          <p class="table-text col-12"></p>

          <p class="header col-12">{{'PROFILE.SHIPPING' | translate}}</p>
          <p class="table-text col-12">GLS</p>

          <p class="header col-12">{{'PROFILE.PAYMENT' | translate}}</p>
          <p class="table-text col-12">{{order.payment_method}}</p>

        </section>
      </section>
    </section>
  </section>

</section>
</section>
