import {AppService} from '../app.service';
import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-latest-blogs',
  templateUrl: './latest-blogs.component.html',
  styleUrls: ['./latest-blogs.component.css']
})
export class LatestBlogsComponent implements OnInit {

  blogs = [];

  constructor(private service: AppService) {
  }

  ngOnInit(): void {
    this.service.getPage('homepage').subscribe(homepagedata => {
      Object.values(homepagedata.data['blog-posts']).forEach(blog => {
        this.blogs.push(blog);
      });
    });
  }

}
