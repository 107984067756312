import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {PersistanceService} from '../persistance.service';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.css']
})
export class PolicyComponent implements OnInit {

  public lang = '';

  constructor(
    private location: Location,
    private persistence: PersistanceService
  ) {
  }

  ngOnInit(): void {
    this.lang = this.persistence.get('lang');
    this.jumpTop();
  }

  back(): void {
    this.location.back();
  }

  jumpTop(): void {
    // @ts-ignore
    window.scrollTo({top: 0, behavior: 'instant'});
  }

}
