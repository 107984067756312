<section class="related-products-section">
  <section class="related-products-wrapper">
    <section class="row justify-content-center">
        <section class="line"></section>
        <h1 class="product-title">{{"RELATED_PRODUCTS.THESE_MAY_ALSO_BE_INTERESTING_FOR_YOU" | translate}}</h1>
        <p class="one-liner">Donec et tortor nec ligula dapibus efficitur in in tortor.</p>
    </section>

    <section class="row justify-content-center">

        <section class="col-12 col-lg-4" *ngFor="let relatedProduct of relatedProducts">

            <!-- <img class="product-image col-12 col-sm-6" src="{{product.images.product_images._1.sources.webp}}"> -->

          <section class="product-description col-12">
                <section class="product-name-wrapper ">
                    <img routerLink="{{'/product/' + getProductSeo(relatedProduct.seo_url)}}" src="{{relatedProduct.images.product_images._1.sources.webp}}" alt=""
                        class="related-prod-image">
                    <p class="product-name">{{relatedProduct.name}}</p>
                    <img *ngIf="relatedProduct.wishlist" class="wishlist" src="../../assets/images/heart.svg">
                </section>

                <p class="product-short-description">
                  <ngx-dynamic-hooks [content]="relatedProduct.short_description"></ngx-dynamic-hooks>
                </p>
                <p class="product-price">{{relatedProduct.formatted_prices.full_price}}</p>
            </section>
        </section>

    </section>

  </section>
</section>
