<section class="container-wrapper">
  <div class="logo-wrapper">
    <img routerLink="/" class="logo" src="assets/images/top-logo.svg">
  </div>

  <section class="container">
    <h1 class="title">
      {{ "COOKIES.TITLE" | translate}}
    </h1>
    <h3 (click)="back()" class="back">{{"MENU.BACK" | translate}}</h3>
    <br>
    <p dir="ltr">
      {{ "COOKIES.COOKIES" | translate}}
      <br/>
      <br/>
      {{ "COOKIES.MUST_HAVE" | translate}}
      <br/>
      {{ "COOKIES.MUST_HAVE_TEXT" | translate}}
    </p>
    <p dir="ltr">
      {{ "COOKIES.CONTROL" | translate}}
    </p>
    <ul>
      <li dir="ltr">
        <p dir="ltr">
          {{ "COOKIES.1" | translate}}
        </p>
      </li>
      <li dir="ltr">
        <p dir="ltr">
          {{ "COOKIES.2" | translate}}
        </p>
      </li>
      <li dir="ltr">
        <p dir="ltr">
          {{ "COOKIES.3" | translate}}
        </p>
      </li>
      <li dir="ltr">
        <p dir="ltr">
          {{ "COOKIES.4" | translate}}
        </p>
      </li>
    </ul>
    <p dir="ltr">
      {{ "COOKIES.STAT_COOKIES" | translate}}
      <br/>
      {{ "COOKIES.STAT_COOKIES_TEXT" | translate}}
    </p>
    <p dir="ltr">
      {{ "COOKIES.MARKET_COOKIES" | translate}}
      <br/>
      {{ "COOKIES.MARKET_COOKIES_TEXT" | translate}}

    </p>
    <p dir="ltr">
      {{ "COOKIES.DEFAULT" | translate}}
    </p>
    <div dir="ltr" align="left">
      <table>
        <colgroup>
          <col width="65"/>
          <col width="56"/>
          <col width="46"/>
          <col width="294"/>
          <col width="77"/>
          <col width="63"/>
        </colgroup>
        <tbody>
        <tr>
          <td>
            <p dir="ltr">
              {{"COOKIES.NAME" | translate}}
            </p>
          </td>
          <td>
            <p dir="ltr">
              {{"COOKIES.OWNER" | translate}}
            </p>
          </td>
          <td>
            <p dir="ltr">
              {{"COOKIES.CATEGORY" | translate}}
            </p>
          </td>
          <td>
            <p dir="ltr">
              {{"COOKIES.JOB" | translate}}
            </p>
          </td>
          <td>
            <p dir="ltr">
              {{"COOKIES.DATA" | translate}}
            </p>
          </td>
          <td>
            <p dir="ltr">
              {{"COOKIES.VALIDITY" | translate}}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p dir="ltr">
              _ga
            </p>
          </td>
          <td>
            <p dir="ltr">
              Google
            </p>
          </td>
          <td>
            <p dir="ltr">
              {{"COOKIES.STATICS" | translate}}
            </p>
          </td>
          <td>
            <p dir="ltr">
              https://developers.google.com/analytics/devguides
              /collection/analyticsjs/cookie-usage
            </p>
            <p dir="ltr">
              {{"COOKIES.COOKIE_1_JOB" | translate}}
            </p>
          </td>
          <td>
            <p dir="ltr">
              {{"COOKIES.COOKIE_1_DATA" | translate}}
            </p>
          </td>
          <td>
            <p dir="ltr">
              23  {{"COOKIES.MONTH" | translate}}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p dir="ltr">
              _fbp
            </p>
          </td>
          <td>
            <p dir="ltr">
              Facebook
            </p>
          </td>
          <td>
            <p dir="ltr">
              Marketing
            </p>
          </td>
          <td>
            <p dir="ltr">
              https://developers.facebook.com/docs/marketing-
              api/conversions-api/parameters/fbp-and-fbc
            </p>
          </td>
          <td>
            <p dir="ltr">
              {{"COOKIES.COOKIE_2_DATA" | translate}}
            </p>
          </td>
          <td>
            <p dir="ltr">
              2 {{"COOKIES.MONTH" | translate}}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p dir="ltr">
              websiteCookie sAccepted
            </p>
          </td>
          <td>
            <p dir="ltr">
              Angular.js
            </p>
          </td>
          <td>
            <p dir="ltr">
              {{"COOKIES.REQUIRED" | translate}}
            </p>
          </td>
          <td>
            <p dir="ltr">
              {{"COOKIES.COOKIE_3_JOB" | translate}}
            </p>
          </td>
          <td>
            <p dir="ltr">
              {{"COOKIES.COOKIE_3_DATA" | translate}}
            </p>
          </td>
          <td>
            <p dir="ltr">
              119 {{"COOKIES.MONTH" | translate}}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p dir="ltr">
              _gac_UA- 152956325-1
            </p>
          </td>
          <td>
            <p dir="ltr">
              Google
            </p>
          </td>
          <td>
            <p dir="ltr">
              {{"COOKIES.STATICS" | translate}}
            </p>
          </td>
          <td>
            <p dir="ltr">
              https://support.google.com/google-ads/answer /7521212
            </p>
          </td>
          <td>
            <p dir="ltr">
              {{"COOKIES.COOKIE_4_DATA" | translate}}
            </p>
          </td>
          <td>
            <p dir="ltr">
              2 {{"COOKIES.MONTH" | translate}}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p dir="ltr">
              SimplePay
            </p>
          </td>
          <td>
            <p dir="ltr">
              OTP Mobil Kft
            </p>
          </td>
          <td>
            <p dir="ltr">
              {{"COOKIES.REQUIRED" | translate}}
            </p>
          </td>
          <td>
            <p dir="ltr">
              {{"COOKIES.COOKIE_5_JOB" | translate}}
            </p>
          </td>
          <td>
            <p dir="ltr">
              {{"COOKIES.COOKIE_5_DATA" | translate}}
            </p>
          </td>
          <td>
            <p dir="ltr">
              11 {{"COOKIES.MONTH" | translate}}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p dir="ltr">
              _gid
            </p>
          </td>
          <td>
            <p dir="ltr">
              Google
            </p>
          </td>
          <td>
            <p dir="ltr">
              {{"COOKIES.STATICS" | translate}}
            </p>
          </td>
          <td>
            <p dir="ltr">
              https://developers.google.com/analytics/devguides
              /collection/analyticsjs/cookie-usage
            </p>
            <p dir="ltr">
              {{"COOKIES.COOKIE_6_JOB" | translate}}
            </p>
          </td>
          <td>
            <p dir="ltr">
              {{"COOKIES.COOKIE_1_DATA" | translate}}
            </p>
          </td>
          <td>
            <p dir="ltr">
              11 {{"COOKIES.MONTH" | translate}}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p dir="ltr">
              api_token
            </p>
          </td>
          <td>
            <p dir="ltr">
              OpenCart
            </p>
          </td>
          <td>
            <p dir="ltr">
              {{"COOKIES.REQUIRED" | translate}}
            </p>
          </td>
          <td>
            <p dir="ltr">
              {{"COOKIES.COOKIE_7_JOB" | translate}}
            </p>
          </td>
          <td>
            <p dir="ltr">
              {{"COOKIES.COOKIE_7_DATA" | translate}}
            </p>
          </td>
          <td>
            <p dir="ltr">
              1 {{"COOKIES.HOUR" | translate}}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p dir="ltr">
              wishlist
            </p>
          </td>
          <td>
            <p dir="ltr">
              OpenCart
            </p>
          </td>
          <td>
            <p dir="ltr">
              {{"COOKIES.REQUIRED" | translate}}
            </p>
          </td>
          <td>
            <p dir="ltr">
              {{"COOKIES.COOKIE_8_JOB" | translate}}
            </p>
          </td>
          <td>
            <p dir="ltr">
              {{"COOKIES.COOKIE_8_DATA" | translate}}
            </p>
          </td>
          <td>
            <p dir="ltr">
              12 {{"COOKIES.MONTH" | translate}}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p dir="ltr">
              currency
            </p>
          </td>
          <td>
            <p dir="ltr">
              OpenCart
            </p>
          </td>
          <td>
            <p dir="ltr">
              {{"COOKIES.REQUIRED" | translate}}
            </p>
          </td>
          <td>
            <p dir="ltr">
              {{"COOKIES.COOKIE_9_JOB" | translate}}
            </p>
          </td>
          <td>
            <p dir="ltr">
              {{"COOKIES.COOKIE_9_DATA" | translate}}

            </p>
          </td>
          <td>
            <p dir="ltr">
              3 {{"COOKIES.WEEK" | translate}}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p dir="ltr">
              language
            </p>
          </td>
          <td>
            <p dir="ltr">
              OpenCart
            </p>
          </td>
          <td>
            <p dir="ltr">
              {{"COOKIES.REQUIRED" | translate}}
            </p>
          </td>
          <td>
            <p dir="ltr">
              {{"COOKIES.COOKIE_10_JOB" | translate}}
            </p>
          </td>
          <td>
            <p dir="ltr">
              {{"COOKIES.COOKIE_10_DATA" | translate}}
            </p>
          </td>
          <td>
            <p dir="ltr">
              3 {{"COOKIES.WEEK" | translate}}
            </p>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <br/>
  </section>
  <h3 (click)="back()" class="back padding-bottom">{{"MENU.BACK" | translate}}</h3>
</section>
