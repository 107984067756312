<section class="space-container">
  <section class="selected-category-wrapper">
    <section class="line"></section>
    <p class="title-large">{{title}}</p>
    <p class="paragraph-text">
      <ngx-dynamic-hooks [content]="description"></ngx-dynamic-hooks>
    </p>

    <section class="categories">
      <section class="container">
        <section class="products row">
          <section class="product-container col-sm-6 col-md-4 col-xl-4" *ngFor="let product of products">
            <div class="product-image-container">
              <!--            <div *ngIf="product.category.name === selectedCategory" class="product-image-container">-->
              <div class="label-container">
                <p *ngIf="product.free_shipping" class="pink-label">{{'PRODUCT.FREE_SHIPPING' | translate}}</p>
              </div>
              <div class="label-container">
                <p *ngIf="product.formatted_prices.discount_percentage" class="pink-label">-
                  {{product.formatted_prices.discount_percentage}}{{'PRODUCT.DISCOUNT' | translate}}</p>
              </div>
              <div class="label-container">
                <p *ngIf="product.flags.length != 0" class="pink-label">{{product.flags}}</p>
              </div>

              <!-- (mouseover)='over()' (mouseout)='out()' -->
              <!-- *ngIf="displayCartMenu" -->
              <div class="purchase-container row justify-content-end">
                <!--              <div class="add-remove-buttons col-12 col-lg-6">-->
                <!--                <img src="../../assets/images/minus_button_white.svg" alt="" class="minus-button"-->
                <!--                     (click)="product.quantity = modifyBasketQuantity('dec', product.quantity)">-->
                <!--                <img src="../../assets/images/plus_button_white.svg" alt="" class="plus-button"-->
                <!--                     (click)="product.quantity = modifyBasketQuantity('inc', product.quantity)">-->
                <!--              </div>-->
                <div class="purchase-button-wrapper col-12">
                  <button class="basket-button" (click)="addToBasket(product.product_id)">
                    <img src="../../assets/images/basket_icon_brown.svg" alt="" class="cart">
                    {{'BUTTON.CART' | translate}}
                    <!--                  class="cart">{{'BUTTON.CART' | translate}}{{'(' + product.quantity + ')'}}-->
                  </button>
                </div>

              </div>
              <img routerLink="{{'/product/' + getProductSeo(product.seo_url)}}" class="product-image col-12 col-sm-6"
                   src="{{product.images.product_images._1.sources.webp}}" alt="product">
            </div>

            <section class="product-description col-12">
              <section class="product-name-wrapper">
                <p routerLink="{{'/product/' + getProductSeo(product.seo_url)}}"
                   class="product-name">{{product.name}}
                </p>
                <img *ngIf="checkWishList(product.product_id, product.wishlist)" class="wishlist" (click)="addWishlist(product.product_id, product.wishlist); product.wishlist = !product.wishlist" src="../../assets/images/heart.svg" alt="add wishlist">
                <img *ngIf="!checkWishList(product.product_id, product.wishlist)" class="wishlist" (click)="addWishlist(product.product_id, product.wishlist); product.wishlist = !product.wishlist" src="../../assets/images/heart_filled.svg" alt="take out wishlist">
              </section>
              <p class="product-short-description">
                <ngx-dynamic-hooks [content]="product.short_description"></ngx-dynamic-hooks>
              </p>
              <p class="product-price" *ngIf="product && !product.formatted_prices.discount_percentage">{{product.formatted_prices.full_price}}</p>
              <div *ngIf="product && product.formatted_prices.discount_percentage" class="price-container">
                <p class="product-price" *ngIf="product && !product.formatted_prices.discount_percentage">
                  {{product && product.price}}</p>

                <div *ngIf="product && product.formatted_prices.discount_percentage" class="price-wrapper">
                  <p class="product-price price-old">
                    {{product.formatted_prices.full_price}}</p>
                  <p class="product-price price-new">
                    {{product.formatted_prices.on_sale_price}}</p>
                </div>
              </div>
            </section>

          </section>
        </section>
      </section>
    </section>
  </section>
  <section class="latest-blogs-wrapper">
    <app-latest-blogs></app-latest-blogs>
  </section>
</section>

