import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppService} from '../app.service';
import {PersistanceService} from '../persistance.service';
import {HostListener} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.css']


})

export class ComingSoonComponent implements OnInit {

  screenHeight: number;
  screenWidth: number;
  isSubmitted: boolean = false;
  subscribeForm = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    policyChecked: new FormControl('false'),
    termsChecked: new FormControl('false')
  });

  policyIsClicked: boolean = false;
  termsIsClicked: boolean = false;
  logoSource: string;
  selectedLanguage: { code: 'hu-hu' };

  constructor(
    public service: AppService,
    private translate: TranslateService,
    private persistance: PersistanceService
  ) {
    this.getScreenSize();
  }

  ngOnInit(): void {
  }


  @HostListener('click', ['$event.target'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }


  toggleTermsButton(): void {
    this.termsIsClicked = !this.termsIsClicked;
    this.checkTermsValue();
  }

  togglePolicyButton(): void {

    this.policyIsClicked = !this.policyIsClicked;
    this.checkPolicyValue();
  }

  checkTermsValue(): void {
    if (this.termsIsClicked === true) {
      this.subscribeForm.controls.termsChecked.setValue({termsChecked: true});
      // @ts-ignore
      document.getElementById('radio-terms').checked = true;
    } else if (this.termsIsClicked === false) {
      this.subscribeForm.controls.termsChecked.setValue({termsChecked: false});
      // @ts-ignore
      document.getElementById('radio-terms').checked = false;
    }
  }

  checkPolicyValue(): void {
    if (this.policyIsClicked === true) {
      this.subscribeForm.controls.policyChecked.setValue({policyChecked: true});
      // @ts-ignore
      document.getElementById('radio-policy').checked = true;
    } else if (this.policyIsClicked === false) {
      this.subscribeForm.controls.policyChecked.setValue({policyChecked: false});
      // @ts-ignore
      document.getElementById('radio-policy').checked = false;
    }
  }

  getRadioValues(buttonId): boolean {
    // @ts-ignore
    return document.getElementById(buttonId).checked;
  }


  subscribeMail(): void {
    if (this.isSubmitted || this.subscribeForm.status !== 'VALID' || !this.getRadioValues('radio-policy') || !this.getRadioValues('radio-terms')) {
      return;
    }
    this.isSubmitted = true;

    // @ts-ignore
    this.persistance.set('def_lang', (document.getElementById('lang-selector').value).split(' ')[1]);

    const email = this.subscribeForm.controls.email.value;
    const name = this.subscribeForm.controls.name.value;
    this.service.subscribeMail(email, 1, 1, name).subscribe(response => {
      if (response.status === 0) {
        const anjaAlert = Swal.mixin({
          customClass: {
            title: 'anja-alert-title',
            confirmButton: 'anja-alert-button',
          }
        });

        anjaAlert.fire({
          icon: 'success',
          title: this.translate.instant('COMING_SOON.SUBSCRIBE_SUCCESSFUL'),
          toast: false,
          focusConfirm: false,
          position: 'center',
          showConfirmButton: true,
          confirmButtonColor: '#3D3D3D',
          iconColor: '#0bbf74a6',
          confirmButtonText: this.translate.instant('LOGIN.OKAY'),

        });
        this.subscribeForm.setValue({
          name: '',
          email: '',
          policyChecked: false,
          termsChecked: false,
        });
      } else if (response.status === 1) {

        const anjaAlert = Swal.mixin({
          customClass: {
            title: 'anja-alert-title',
            confirmButton: 'anja-alert-button',
          }
        });
        anjaAlert.fire({
          toast: false,
          position: 'center',
          icon: 'error',
          title: this.translate.instant('COMING_SOON.SUBSCRIBE_FAILED'),
          showConfirmButton: true,
          iconColor: '#E7B2AB',
          confirmButtonText: this.translate.instant('LOGIN.OKAY'),
          confirmButtonColor: '#3D3D3D',


        });
      }
      this.isSubmitted = false;

    });
  }

}


