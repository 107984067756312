import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AppService} from "../app.service";
import {ActivatedRoute} from '@angular/router';
import {PersistanceService} from "../persistance.service";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import Swal from "sweetalert2";

@Component({
  selector: 'app-modify-password',
  templateUrl: './modify-password.component.html',
  styleUrls: ['./modify-password.component.css']
})
export class ModifyPasswordComponent implements OnInit {
  showProfile = false;
  editFields = true;

  loginForm = new FormGroup({
    password: new FormControl('', Validators.required),
    cpassword: new FormControl('', Validators.required)
  });

  personalDataForm = new FormGroup({
    firstname: new FormControl('', Validators.required),
    lastname: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    address_2: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),
    zone_id: new FormControl('', Validators.required),
    irszam: new FormControl('', Validators.required),
    cim: new FormControl('', Validators.required),
    hazszam: new FormControl('', Validators.required),
    egyebhazinfo: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    country_id: new FormControl('', Validators.required),
  });

  orders = [];

  constructor(
    private service: AppService,
    private persistance: PersistanceService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService

  ) {
  }

  ngOnInit(): void {
    window.scrollTo({
      top: 0,
      left: 0,
      // @ts-ignore
      behavior: 'instant',
    });

    if (this.persistance.get('logged_in_user') === 'true') {
      this.showProfile = true;

      this.service.getAccountData().subscribe(response => {
        this.orders = response.data.orders;
        //console.log(response);

        this.personalDataForm.controls.firstname.setValue(response.data.profile.firstname);
        this.personalDataForm.controls.lastname.setValue(response.data.profile.lastname);
        this.personalDataForm.controls.phone.setValue(response.data.profile.telephone);

        if (response.data.addresses.length !== 0) {
          const addressName = response.data.addresses[0].address_1.replace(/[0-9]/g, '');
          const addressNumber = response.data.addresses[0].address_1.split(' ')[response.data.addresses[0].address_1.split(' ').length - 1];
          this.personalDataForm.controls.irszam.setValue(response.data.addresses[0].postcode);
          this.personalDataForm.controls.city.setValue(response.data.addresses[0].city);
          this.personalDataForm.controls.cim.setValue(addressName);
          this.personalDataForm.controls.hazszam.setValue(addressNumber);
          this.personalDataForm.controls.address_2.setValue(response.data.addresses[0].address_2);
        }
      });
    }
  }


  setNewPass(): void {
    this.route.params.subscribe(params => {
        const code = params.code;
        const password = this.loginForm.controls.password.value;
        const cpassword = this.loginForm.controls.cpassword.value;

        this.service.setNewPassword(password, cpassword, code).subscribe(res => {
            if(res.status === 0) {
              const anjaAlert = Swal.mixin({
                customClass: {
                  title: 'anja-alert-title',
                  confirmButton: 'anja-alert-button',
                }
              });

              anjaAlert.fire({
                icon: 'success',
                title: this.translate.instant('PROFILE.NEW_PASS_SUCCESS'),
                toast: false,
                focusConfirm: false,
                position: 'center',
                showConfirmButton: true,
                confirmButtonColor: '#3D3D3D',
                iconColor: '#0bbf74a6',
                confirmButtonText: this.translate.instant('LOGIN.OKAY'),
              });

            } else if (res.status === 1) {
              const anjaAlert = Swal.mixin({
                customClass: {
                  title: 'anja-alert-title',
                  confirmButton: 'anja-alert-button',
                }
              });
              anjaAlert.fire({
                toast: false,
                position: 'center',
                icon: 'error',
                title: res.error.msg,
                text: res.error.errors.code,
                showConfirmButton: true,
                iconColor: '#E7B2AB',
                confirmButtonText: this.translate.instant('LOGIN.OKAY'),
                confirmButtonColor: '#3D3D3D',
              });

            }
        });

    });


  }

  login(): void {
    if (this.service.goPayment) {
      this.service.goPayment = false;
      this.router.navigate(['/payment/personal-data']);
    }

    const email = this.loginForm.controls.email.value;
    const password = this.loginForm.controls.password.value;

    this.service.guestLogin(email, password).subscribe(
      response => {
        if (response.status === 0) {
          this.persistance.set('api_token', response.data.api_token);
          this.persistance.set('logged_in_user', true);

          const anjaAlert = Swal.mixin({
            customClass: {
              title: 'anja-alert-title',
              confirmButton: 'anja-alert-button',
            }
          });

          anjaAlert.fire({
            icon: 'success',
            title: this.translate.instant('LOGIN.LOGIN_SUCCESSFUL'),
            toast: false,
            focusConfirm: false,
            position: 'center',
            showConfirmButton: true,
            confirmButtonColor: '#3D3D3D',
            iconColor: '#0bbf74a6',
            confirmButtonText: this.translate.instant('LOGIN.OKAY'),
          });

          if (this.service.routeAfterLogin !== '') {
            setTimeout(() => {
              this.router.navigate([this.service.routeAfterLogin]);
            }, 2000);
          } else {
            this.router.navigate(['/']);

          }

        } else if (response.status === 1) {
          const anjaAlert = Swal.mixin({
            customClass: {
              title: 'anja-alert-title',
              confirmButton: 'anja-alert-button',
            }
          });
          anjaAlert.fire({
            toast: false,
            position: 'center',
            icon: 'error',
            title: this.translate.instant('LOGIN.LOGIN_FAILED'),
            text: response.error.msg,
            showConfirmButton: true,
            iconColor: '#E7B2AB',
            confirmButtonText: this.translate.instant('LOGIN.OKAY'),
            confirmButtonColor: '#3D3D3D',
          });
        }
        // tslint:disable-next-line:no-unused-expression
      }, error => {
        //console.log('ERROR TEXT', error);
      });
  }

  forgot(): void {
    const anjaAlert = Swal.mixin({
      customClass: {
        title: 'anja-alert-title',
        confirmButton: 'anja-alert-button',
      }
    });

    anjaAlert.fire({
      icon: 'success',
      title: this.translate.instant('PROFILE.SENT_PASSWORD_RESET'),
      toast: false,
      focusConfirm: false,
      position: 'center',
      showConfirmButton: true,
      confirmButtonColor: '#3D3D3D',
      iconColor: '#0bbf74a6',
      confirmButtonText: this.translate.instant('LOGIN.OKAY'),
    });
    this.service.forgotPswd(this.loginForm.controls.email.value).subscribe(response => {
      //console.log(response);
    }, error => {
      this.service.refreshToken(this.persistance.get('logged_in_user'));
    });
  }

  roundTotal(total): number {
    return Math.round(total);
  }

  editProfileData(): void {
    // tslint:disable-next-line:max-line-length
    this.service.setAccountData(this.personalDataForm.controls.firstname.value, this.personalDataForm.controls.lastname.value, this.personalDataForm.controls.cim.value + ' ' + this.personalDataForm.controls.hazszam.value, this.personalDataForm.controls.address_2.value, this.personalDataForm.controls.city.value, '', this.personalDataForm.controls.irszam.value, this.personalDataForm.controls.phone.value, this.personalDataForm.controls.firstname.value, this.personalDataForm.controls.lastname.value).subscribe(response => {
      const anjaAlert = Swal.mixin({
        customClass: {
          title: 'anja-alert-title',
          confirmButton: 'anja-alert-button',
        }
      });

      anjaAlert.fire({
        icon: 'success',
        title: this.translate.instant('PROFILE.SUCCESS'),
        toast: true,
        timerProgressBar: true,
        timer: 3000,
        position: 'center',
        focusConfirm: false,
        showConfirmButton: true,
        confirmButtonColor: '#3D3D3D',
        iconColor: '#0bbf74a6',
        confirmButtonText: this.translate.instant('LOGIN.OKAY'),
      });
    });
  }

  logout(): void {
    this.persistance.remove('logged_in_user');

    const anjaAlert = Swal.mixin({
      customClass: {
        title: 'anja-alert-title',
        confirmButton: 'anja-alert-button',
      }
    });

    anjaAlert.fire({
      icon: 'success',
      title: this.translate.instant('PROFILE.SUCCESS_LOGOUT'),
      toast: true,
      timerProgressBar: true,
      timer: 3000,
      position: 'center',
      focusConfirm: false,
      showConfirmButton: true,
      confirmButtonColor: '#3D3D3D',
      iconColor: '#0bbf74a6',
      confirmButtonText: this.translate.instant('LOGIN.OKAY'),
    });
  }
}
