import {Component, OnInit} from '@angular/core';
import {AppService} from '../app.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {

  categories = [];
  prices = [];
  promotions = [];
  filteredProducts = [];
  selectedCategory = 'Összes';
  filterCategories = [];

  constructor(
    private service: AppService
  ) {
  }

  ngOnInit(): void {
    this.filterCategories.push('filter_category_id[]=60&');
    this.sendFilters(this.filterCategories, '', '');
  }

  modifyBasketQuantity(expression, quantity): number {
    if (expression === 'inc') {
      return quantity + 1;
    } else if (expression === 'dec') {
      const tempResult = quantity - 1;
      if (tempResult <= 0) {
        return 0;
      } else {
        return tempResult;
      }
    }
  }

  sendFilters(categories, prices, promotion): void {
    let filterCategories = '';
    categories.forEach(category => {
      filterCategories = filterCategories.concat(category);
    });

    this.service.filterProducts('', '', '').subscribe(response => {

      Object.values(response.data.filter.prices).forEach(prices => {
        this.prices.push(prices);
      });

      Object.values(response.data.filter.include_promotions).forEach(promotions => {
        this.promotions.push(promotions);
      });

      Object.values(response.data.filter.categories).forEach(categories => {
        this.categories.push(categories);
      });

      Object.values(response.data.products).forEach(product => {
        // @ts-ignore
        product.quantity = 0;
        this.filteredProducts.push(product);
      });
    });
  }

  getProductSeo(seoUrl): string {
    return seoUrl.split(';').pop();
  }
}
