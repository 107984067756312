import {Component, OnInit} from '@angular/core';
import {PersistanceService} from '../persistance.service';
import {Router} from '@angular/router';
import {AppService} from '../app.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {

  constructor(
    private persistance: PersistanceService,
    public service: AppService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    window.scrollTo({
      top: 0, left: 0,
      // @ts-ignore
      behavior: 'instant',
    });

    if (this.persistance.get('logged_in_user') === 'true') {
      this.router.navigate(['/payment/personal-data']);
    }
  }

}
