<section class="space-container">
<section class="blog-related-products-container container">
  <!--  <img class="blog-image" src="{{blog && blog.data.post.images.webp}}">-->
  <img class="blog-image" src="assets/images/anja_blog.webp">

  <section class="row justify-content-center">
    <section class="blog-container col-12 col-lg-7 col-xl-7">

      <p class="blog-title">{{blog && blog.data.post.title}}</p>
      <!--      <p class="blog-lead" >-->
      <!--        <ngx-dynamic-hooks [content]="blog && blog.data.post.lead"></ngx-dynamic-hooks>-->
      <!--      </p>-->
      <!--<p class="">{{blog && blog.data.post.lead}}</p>-->
      <p class="blog-text">
        <ngx-dynamic-hooks [content]="blog && blog.data.post.text"></ngx-dynamic-hooks>
      </p>
      <!--<p [innerHTML]="el | safeHtml" class=""></p>-->
    </section>
    <section class="related-products-container col-12 col-lg-5 col-xl-5">
      <section class="related-products-wrapper row justify-content-center">
        <h1 class="related-products-title">{{'BLOGS.PRODUCTS_RELATED_TO_TOPIC' | translate}}</h1>
        <section class="row " *ngFor="let product of relatedProducts ">
          <div class="product-container col-12 col-sm-6 col-md-4 col-lg-12">
            <img routerLink="{{'/product/' + getProductSeo(product.seo_url)}}"
                 class="product-image" src="{{product.images.product_images._1.sources.webp}}">
            <!--ToDo: Add *ngIf="product.wishlist" to the image for final output!!!-->
            <h1 routerLink="{{'/product/' + getProductSeo(product.seo_url)}}"
                class="product-title">{{product.name}}
<!--              <img class="wishlist-icon" src="../../assets/images/heart.svg">-->
            </h1>
            <p class="product-description">
              <ngx-dynamic-hooks [content]="product.short_description"></ngx-dynamic-hooks>
            </p>
            <div class="price-container row">
              <p class="product-price col-6"
              >{{product.formatted_prices.full_price}}</p>
              <p class="price-new col-6">{{product.formatted_prices.on_sale_price}}</p>
            </div>

          </div>
        </section>
      </section>
    </section>
  </section>
</section>
</section>
