import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-our-values',
  templateUrl: './our-values.component.html',
  styleUrls: ['./our-values.component.css']
})
export class OurValuesComponent implements OnInit {

  selectedValue = '';

  constructor(
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.jumpTop();

    this.route.params.subscribe(params => {
      this.selectedValue = params.value;
    });
  }

  jumpTop(): void {
    // @ts-ignore
    window.scrollTo({ top: 0, behavior: 'instant' });
  }
  

}
