import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-gls-point-map',
  templateUrl: './gls-point-map.component.html',
  styleUrls: ['./gls-point-map.component.css']
})
export class GlsPointMapComponent implements OnInit {
  @Output() glsPointSelected: EventEmitter<object> = new EventEmitter<object>()
  @Input() selectedGlsPoint: string | undefined
  @Input() selectedGlsPointAddress: string | undefined

  constructor() {}
  
  //@ts-ignore
  public glsMap: GLSPSMap

  ngOnInit(): void {
    console.log(this.selectedGlsPoint)
    this.initGLSPSMap()
    window['glsPSMap_OnSelected_Handler'] = (e) => {
      this.glsPointSelected.emit(e)
    }
  }

  public initGLSPSMap() {
    //@ts-ignore
    this.glsMap = new GLSPSMap();
    //@ts-ignore
    this.glsMap.init('HU', 'big-canvas', this.selectedGlsPointAddress ?? '1116,Budapest,HU', 1, null,  1, 1, null);
  }

}
