import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

  transactionId = '0';
  orderId = '0';

  constructor(
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.transactionId = params.id;
      this.orderId = params.orderId;
    });
    this.jumpTop();
  }

  jumpTop(): void {
    // @ts-ignore
    window.scrollTo({top: 0, behavior: 'instant'});
  }

  startPayment() {
    document.location.href = "https://api.anja.hu/index.php?route=extension/payment/simple_otp_payment/startwithid&orderId=" + this.orderId;
  }
}
