import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
    this.jumpTop();
  }

  jumpTop(): void {
    // @ts-ignore
    window.scrollTo({top: 0, behavior: 'instant'});
  }
}
