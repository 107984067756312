import {Injectable} from '@angular/core';
import {PersistanceService} from './persistance.service';

@Injectable({
  providedIn: 'root'
})
export class WishlistService {

  private wishlist: number[] = this.loadCart();

  constructor(private persistence: PersistanceService) {

  }

  addToWishlist(prodID): boolean {
    let itemAlreadyInCart = false;

    this.wishlist.forEach(wishProdId => {
      if (prodID === wishProdId) {
        itemAlreadyInCart = true;
      }
    });

    if (!itemAlreadyInCart) {
      this.wishlist.push(prodID);
      this.saveCart();
    } else {
      this.removeItem(prodID);
    }
    return false;
  }

  checkWishlist(prodId): boolean {
    let itemAlreadyInCart = false;

    this.wishlist.forEach(wishProdId => {
      if (prodId === wishProdId){
        itemAlreadyInCart = true;
      }
    });
    return itemAlreadyInCart;
  }

  private getWishlist(): Array<number> {
    return this.wishlist;
  }

  private loadCart(): Array<any> {

    return this.wishlist = this.persistence.get('wishlist') && this.persistence.get('wishlist') !== '' ? JSON.parse(this.persistence.get('wishlist')) : [];
  }

  private saveCart(): void {
    this.persistence.set('wishlist', JSON.stringify(this.wishlist));
  }

  private removeItem(prodId: any): void {
    this.wishlist.forEach((wishProdId, index) => {
      if (prodId === wishProdId) {
        this.wishlist.splice(index, 1);
      }
    });
    this.saveCart();
  }
}
