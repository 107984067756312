import {Component, OnInit} from '@angular/core';
import {MainComponent} from '../main/main.component';
import {PersistanceService} from '../persistance.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  lang = '';

  constructor(
    private mainPage: MainComponent,
    private persistence: PersistanceService
  ) {
  }

  ngOnInit(): void {
    this.lang = this.persistence.get('lang');

    window.scrollTo({
      top: 0,
      left: 0,
      // @ts-ignore
      behavior: 'instant',
    });
  }

  scrollToValues(): void {
    this.mainPage.scrollToValues();
  }

  goToLink(url: string): void {
    window.open(url, '_blank');
  }

}
