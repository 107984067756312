<head>
<!--  &lt;!&ndash; Global site tag (gtag.js) - Google Ads: 10837993304 &ndash;&gt; <script async src="https://www.googletagmanager.com/gtag/js?id=AW-10837993304"></script> <script> window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-10837993304'); </script>-->
</head>
<section class="space-container">
  <section *ngIf="!showProfile" class="login-section">
    <section class="container">
      <section class="row justify-content-center justify-content-lg-between">
        <section class="window col-12 col-lg-6">
          <section class="window-header">
            <p class="window-title">{{"PROFILE.NEW_PASS" | translate}}</p>
          </section>

          <section class="window-top">
            <form [formGroup]="loginForm">
              <br>
              <input class="input-field" type="password" id="password" name="password"
                     formControlName="password" placeholder="{{'LOGIN.PASSWORD_PLACEHOLDER' | translate}}" required>
              <br>
              <input class="input-field" type="password" id="cpassword" name="cpassword"
                     formControlName="cpassword" placeholder="{{'PROFILE.CONFIRM_PASS' | translate}}" required>
              <br>
              <br>

              <section class="row button-wrapper">
                <button *ngIf="loginForm.status === 'VALID'" (click)="setNewPass()"
                        class="button login-button col-12">{{"PROFILE.CONFIRM_NEW_PASS" | translate}}</button>
                <button *ngIf="loginForm.status === 'INVALID'"
                        class="button login-button disabled-button col-12">{{"PROFILE.CONFIRM_NEW_PASS" | translate}}</button>
              </section>
            </form>
          </section>

          <section class="thin-line"></section>

          <section class="window-bottom">

          </section>
        </section>

        <section class="fancy-image-section col-12 col-lg-6">
          <img class="login-image" src="assets/images/login_images_group.png" alt="">
        </section>
      </section>
    </section>
  </section>
</section>
