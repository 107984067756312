<section class="ultra-container container ">
  <section class="mega-container container">
    <section class="bg-wrapper container">
      <section class="row justify-content-center">
        <section class="contact-container">
          <h1 class="contact-title">{{"CONTACT.HI_CONTACT_US" | translate}}</h1>
<!--          <p class="contact-text">{{"CONTACT.CONTACT_LEAD" | translate}}</p>-->
          <section class="container">
            <section id="labels" class="row justify-content-center justify-content-lg-start">
              <div class="label-wrapper col-12 col-md-6 col-lg-5">
                <div class="label phone-label row justify-content-center">
                  <div class="image-wrapper col-4">
                    <img class="phone" src="assets/images/phone_icon.svg">
                  </div>

                  <div class="text-wrapper col-8">
                    <p class="label-title">{{"CONTACT.PHONE" | translate}}</p>
                    <p class="label-text"><a href="tel:+36 30 921 3883">+36 30 921 3883</a></p>
                  </div>
                </div>
              </div>
              <div class="label-wrapper col-12 col-md-6">
                <div id="label2" class="label row">
                  <div class="image-wrapper col-4">
                    <img class="mail" src="assets/images/mail_icon.svg">
                  </div>

                  <div class="text-wrapper col-8">
                    <p class="label-title">{{"CONTACT.EMAIL" | translate}}</p>
                    <p class="label-text"><a href="mailto:ugyfelszolgalat@anja.hu">ugyfelszolgalat@anja.hu</a></p>
                  </div>
                </div>
              </div>
            </section>
          </section>
            <p class="contact-text under-contact-text">{{"CONTACT.OPEN_HOURS" | translate}}</p>
            <p class="contact-text firm-data">{{"CONTACT.FIRM_DATA" | translate}}</p>
        </section>
      </section>
    </section>
  </section>
</section>
