<section class="checkout-container">
  <section class="row justify-content-center">
    <section class="direction-panel-wrapper col-12 col-lg-6">
      <div class="direction-panel">
        <h1 class="direction-title">{{"CHECKOUT.WHATS_NEXT" | translate}}</h1>
        <div class="row redirect-buttons">
          <button class="redirect-button col-12" routerLink="/login" (click)="service.goPayment = true">{{"CHECKOUT.LOGIN" | translate}}</button>
          <button class="redirect-button col-12" routerLink="/registration" (click)="service.goPayment = true">{{"CHECKOUT.REGISTRATION" | translate}}</button>
        </div>
        <p class="shop-as-guest-text" routerLink="/payment/personal-data">{{"CHECKOUT.AS_GUEST" | translate}}</p>
      </div>
    </section>
    <section class="basket-wrapper col-12">
    </section>
  </section>
</section>

