import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {Router} from "@angular/router";

@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.css']
})
export class CookieComponent implements OnInit {

  constructor(
    private location: Location,
    private router: Router) {
  }


  ngOnInit(): void {
    this.jumpTop();
  }

  back(): void {
    this.location.back();
    setTimeout(() => {
      var ele = document.getElementById('footer');
      window.scroll(ele.offsetLeft,ele.offsetTop);
    }, 200);




  }

  jumpTop(): void {
    // @ts-ignore
    window.scrollTo({top: 0, behavior: 'instant'});
  }
}
