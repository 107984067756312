import {Component, OnInit} from '@angular/core';
import {NavbarComponent} from '../navbar/navbar.component';
import {AppService} from '../app.service';
import {TranslateService} from '@ngx-translate/core';
import Swal from 'sweetalert2';
import {Router} from '@angular/router';
import {PersistanceService} from '../persistance.service';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.css']
})
export class BasketComponent implements OnInit {

  productsInBasket = [];
  totalPrice = '';
  totalPriceOriginal = '';
  couponOff = '';
  coupon = '';

  isBasketEmpty = true;

  constructor(
    public navbar: NavbarComponent,
    public service: AppService,
    private translate: TranslateService,
    private persistance: PersistanceService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.getBasket();
  }

  getBasket(): void {
    this.productsInBasket = [];
    this.service.getCart().subscribe(response => {
      this.service.getItemsInBasket();
      Object.values(response.data.products).forEach(product => {
        this.productsInBasket.push(product);
      });
      this.CheckIfEmpty();
      this.coupon = this.checkCoupon(response.data);
      this.totalPrice = this.getTotalPrice(response.data.totals, 'Total');
      this.totalPriceOriginal = this.getTotalPrice(response.data.totals, 'Sub-Total');
      this.couponOff = this.getTotalPrice(response.data.totals, this.coupon);
    }, error => {
      this.service.refreshToken(this.persistance.get('logged_in_user'));
    });
  }

  routerGuard(): void {
    this.service.showBasket = false;
    if (this.isBasketEmpty) {
      const anjaAlert = Swal.mixin({
        customClass: {
          title: 'anja-alert-title',
          confirmButton: 'anja-alert-button',
          cancelButton: 'anja-cancel-button',
        }
      });
      anjaAlert.fire({
        icon: 'warning',
        title: this.translate.instant('BASKET.EMPTY_CART_WARNING'),
        toast: false,
        timerProgressBar: true,
        position: 'center',
        focusConfirm: false,
        showConfirmButton: true,
        showCancelButton: false,
        confirmButtonColor: '#3D3D3D',
        iconColor: '#E01E70',
        confirmButtonText: this.translate.instant('LOGIN.OKAY'),
      });
    } else if (this.isBasketEmpty === false) {
      this.service.goPaymentAfterLogin = true;
      this.router.navigate(['checkout']);
    }
  }

  CheckIfEmpty(): void {
    if (this.productsInBasket.length) {
      this.isBasketEmpty = false;
    }
  }

  removeItem(cartId): void {
    const anjaAlert = Swal.mixin({
      customClass: {
        title: 'anja-alert-title',
        confirmButton: 'anja-alert-button',
        cancelButton: 'anja-cancel-button',
      }
    });

    anjaAlert.fire({
      icon: 'warning',
      title: this.translate.instant('BASKET.CONFIRM_REMOVE'),
      toast: true,
      timerProgressBar: true,
      position: 'center',
      focusConfirm: false,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3D3D3D',
      iconColor: '#E01E70',
      confirmButtonText: this.translate.instant('BASKET.REMOVE'),
      cancelButtonText: this.translate.instant('BASKET.CANCEL'),
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.service.removeCart(cartId).subscribe(response => {
          this.getBasket();
          if (response.data.status === 0) {
          } else if (response.data.status === 1) {
          }
        }, error => {
          this.service.refreshToken(this.persistance.get('logged_in_user'));
        });
      }
    });
  }

  /* ToDo: Error handling */
  modifyItem(cartId, quantity): void {
    this.service.editCart(cartId, quantity).subscribe(response => {
      this.getBasket();
    });
  }

  getProductOption(product): boolean {
    return false;
  }

  getTotalPrice(totals, totalPrice): string {
    const found = totals.find(total => total.title === totalPrice);
    if (found) {
      return found.text;
    } else {
      return '';
    }
  }

  checkCoupon(data): string {
    if (data && data.coupon && data.coupon.coupon) {
      return data.coupon.coupon;
    }
  }

  get onPaymentPage() {
    //console.log(this.router.url.split('/'))
    return this.router.url.split('/')[1] === 'payment';
  }
}
