<section class="ultra-container container ">
  <section class="mega-container container">
    <section class="bg-wrapper container">
      <section class="row justify-content-center">
        <section class="contact-container row justify-content-center">
          <section class="container">
            <h1 class="contact-title">{{"ERROR.TITLE" | translate}}</h1>

            <section *ngIf="transactionId !== '0'">
              <p class="contact-text">{{"ERROR.TRANSACTION_ID" | translate}} {{transactionId}}</p>
              <button class="button" (click)="startPayment()">Fizetés újraindítása</button>
              <br><br>
            </section>

            <p class="contact-text">{{"ERROR.DESCRIPTION" | translate}}</p>

            <img src="../../assets/images/anja_sign.svg">
          </section>
        </section>
      </section>
    </section>
  </section>
</section>
