<section class="container">
  <section class="row">
    <section class="filter-main-container col-12 col-md-6">
      <section class="row justify-content-center">
        <div class="open-mobile-filter">
          <h1 class="title-text">{{"PRODUCT.FILTER_CATEGORIES" | translate}} <img
            src="../../assets/images/arrow_down.svg"
            alt="" class="down-arrow"></h1>
        </div>
        <p class="thin-line"></p>
        <div class="product-category-container">
          <h1 class="title-text">{{"PRODUCT.PRODUCT_CATEGORY" | translate}}</h1>
          <p class="thin-line"></p>
          <section *ngFor="let category of categories" class="product-main-categories">
            <input type="checkbox" name='{{category.name}}' class="check-box">
            <label class="product-main-category">{{category.name}}</label>
          </section>

        </div>
        <div class="product-price-container">
          <h1 class="title-text">{{"PRODUCT.PRODUCT_PRICE" | translate}}</h1>
          <p class="thin-line"></p>
          <section *ngFor="let price of prices">
            <input type="checkbox" name='{{price}}' class="check-box">
            <label class="product-main-category">{{price}}</label>
          </section>

        </div>
        <div class="promotion-products-container">
          <h1 class="title-text">{{"PRODUCT.PROMOTION_PRODUCTS" | translate}}</h1>
          <p class="thin-line"></p>
          <input type="checkbox" name="showPromotions" class="check-box">
          <label class="">{{"PRODUCT.SHOW_PROMOTION_PRODUCTS" | translate}}</label>
        </div>
      </section>
    </section>
    <section class="products-wrapper col-12 col-md-6">
      <section class="categories">
        <section class="container">
          <section class="products row">
            <section class="product-container col-sm-6 col-md-4 col-xl-4" *ngFor="let product of filteredProducts">
              <div class="product-image-container">
                <!--            <div *ngIf="product.category.name === selectedCategory" class="product-image-container">-->
                <div class="label-container">
                  <p *ngIf="product.free_shipping" class="pink-label">{{'PRODUCT.FREE_SHIPPING' | translate}}</p>
                </div>
                <div class="label-container">
                  <p *ngIf="product.formatted_prices.discount_percentage" class="pink-label">-
                    {{product.formatted_prices.discount_percentage}}{{'PRODUCT.DISCOUNT' |
                      translate}}</p>
                </div>
                <div class="label-container">
                  <p *ngIf="product.flags != 0" class="pink-label">{{product.flags}}</p>
                </div>

                <!-- (mouseover)='over()' (mouseout)='out()' -->
                <!-- *ngIf="displayCartMenu" -->
                <div class="purchase-container row justify-content-space-between">
                  <div class="add-remove-buttons col-12 col-lg-6">
                    <img src="../../assets/images/minus_button_white.svg" alt="" class="minus-button"
                         (click)="product.quantity = modifyBasketQuantity('dec', product.quantity)">
                    <img src="../../assets/images/plus_button_white.svg" alt="" class="plus-button"
                         (click)="product.quantity = modifyBasketQuantity('inc', product.quantity)">
                  </div>
                  <div class="purchase-button-wrapper col-12 col-lg-6">
                    <button class="basket-button" (click)="addToBasket(product, product.id, 0)"><img
                      src="../../assets/images/basket_icon_brown.svg" alt=""
                      class="cart">{{'BUTTON.CART' | translate}}{{'(' + product.quantity + ')'}}
                    </button>
                  </div>

                </div>

                <img routerLink="{{'/product/' + getProductSeo(product.seo_url)}}" class="product-image col-12 col-sm-6"
                     src="{{product.images.product_images._1.sources.webp}}">
              </div>

              <section class="product-description col-12">
                <section class="product-name-wrapper">
                  <p routerLink="{{'/product/' + getProductSeo(product.seo_url)}}" class="product-name">{{product.name}}
                  </p>
                  <img *ngIf="product.wishlist" class="wishlist" src="../../assets/images/heart.svg">
                </section>
                <p id="test" class="product-short-description">
                  <ngx-dynamic-hooks [content]="product.short_description"></ngx-dynamic-hooks>
                </p>
                <p class="product-price"></p>
              </section>

            </section>
          </section>
        </section>
      </section>
    </section>
  </section>
</section>
