<section class="space-container">
<section class="top-img-container">
  <img class="top-img" src="assets/images/landing_top_img.webp">
  <section class="text-with-button-wrapper">
    <p class="teaser-text">{{'LANDING.TEASER' | translate}}</p>
    <button class="basket-button" (click)="getMagicOil()">
      <img src="../../assets/images/basket_icon_brown.svg" alt="" class="cart">{{'BUTTON.CART' | translate}}
    </button>
    <section class="changer-container container">
      <section class="row">
        <section class="changer-wrapper col-3" (click)="counter = 0">
          <section class="logo-wrapper">
            <img class="changer-logo" src="assets/images/for_hair.svg">
          </section>
          <p class="changer-text">{{'LANDING.FOR_HAIR' | translate}}</p>
          <section class="separator"></section>
        </section>

        <section class="changer-wrapper col-3" (click)="counter = 1">
          <section class="logo-wrapper">
            <img class="changer-logo" src="assets/images/for_face.svg">
          </section>
          <p class="changer-text">{{'LANDING.FOR_FACE' | translate}}</p>
          <section class="separator"></section>
        </section>

        <section class="changer-wrapper col-3" (click)="counter = 2">
          <section class="logo-wrapper">
            <img class="changer-logo" src="assets/images/for_skin.svg">
          </section>
          <p class="changer-text">{{'LANDING.FOR_SKIN' | translate}}</p>
          <section class="separator"></section>
        </section>

        <section class="changer-wrapper col-3" (click)="counter = 3">
          <section class="logo-wrapper">
            <img class="changer-logo" src="assets/images/for_belly.svg">
          </section>
          <p class="changer-text">{{'LANDING.FOR_BELLY' | translate}}</p>
        </section>
      </section>
    </section>
  </section>
  <section class="changed-container container">
    <p class="counter">{{changer[counter].counter}}/4</p>
    <p class="title">{{changer[counter].title | translate}}</p>
    <p class="lead">{{changer[counter].lead | translate}}</p>
    <p class="description">{{changer[counter].description | translate}}</p>
    <section class="arrows-wrapper">
      <section class="arrow-wrapper">
        <img class="arrow" src="assets/images/arrow_left.svg" (click)="changeCounter('dec')">
      </section>
      <section class="arrow-wrapper">
        <img class="arrow" src="assets/images/arrow_right.svg" (click)="changeCounter('inc')">
      </section>
    </section>
    <img class="img-between" src="assets/images/landing_img_between.webp" alt="">
    <img class="img-left" src="assets/images/landing_img.webp" alt="">
    <img class="img-between-desktop" src="assets/images/hands.webp" alt="">

    <img class="desc-img-desktop" src="assets/images/landing_img_2.webp" alt="">

    <img class="desc-img-desktop desc-img-desktop-2" src="assets/images/landing_img_3.webp" alt="">
  </section>
</section>
<section class="product-description-container">
  <img class="img-left-desktop" src="assets/images/landing_img.webp" alt="">

  <section class="container">
    <section class="row">
      <section class="product-description-wrapper">
        <p class="title">{{'LANDING.PRODUCT_DESC_TITLE' | translate}}</p>
        <p class="description">{{'LANDING.PRODUCT_DESC' | translate}}</p>
        <button class="basket-button" routerLink="/product/magic-oil">{{'BUTTON.PRODUCT' | translate}}
        </button>
      </section>

      <img class="desc-img" src="assets/images/landing_img_2.webp" alt="">
      <p class="title">{{'LANDING.AGENT' | translate}}</p>

      <section *ngFor="let agent of agents" class="agent-wrapper">
        <p class="lead">{{agent.name | translate}}</p>
        <p class="description">{{agent.effect | translate}}</p>
      </section>

      <img class="desc-img desc-img-2" src="assets/images/landing_img_3.webp" alt="">

      <section class="component-wrapper">
        <p class="title">{{'LANDING.COMPONENTS' | translate}}</p>
        <p class="description">{{'LANDING.ACTUAL_COMPONENTS' | translate}}</p>
      </section>

    </section>
  </section>
</section>
<section class="magic-container">
  <p class="magic-text">{{'LANDING.MAGIC' | translate}}</p>
  <button class="basket-button" (click)="getMagicOil()">
    <img src="../../assets/images/basket_icon_brown.svg" alt="" class="cart">{{'BUTTON.CART' | translate}}
  </button>
</section>
</section>
