import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppService} from '../app.service';
import {PersistanceService} from '../persistance.service';
import {TranslateService} from '@ngx-translate/core';
import Swal from 'sweetalert2';
import {Router} from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  isSubmitted: boolean = false;

  isSubClicked: boolean = true;

  registerForm = new FormGroup({
    firstname: new FormControl('', Validators.required),
    lastname: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    confirm: new FormControl('', Validators.required),
    policyChecked: new FormControl('false'),
    termsChecked: new FormControl('false'),
    isSubbed: new FormControl('true'),
  });

  policyIsClicked: boolean = false;
  termsIsClicked: boolean = false;
  selectedLanguage: any;

  constructor(
    public service: AppService,
    private router: Router,
    private translate: TranslateService,
    private persistance: PersistanceService,
  ) {
  }

  ngOnInit(): void {
  }

  toggleTermsButton(): void {
    this.termsIsClicked = !this.termsIsClicked;
    this.checkTermsValue();
  }

  togglePolicyButton(): void {
    this.policyIsClicked = !this.policyIsClicked;
    this.checkPolicyValue();
  }

  toggleSubButton(): void {
    this.isSubClicked = !this.isSubClicked;
    this.checkSubscribe();
  }

  checkSubscribe(): void {
    if (this.isSubClicked === true) {
      this.registerForm.controls.isSubbed.setValue({isSubbed: true});
      // @ts-ignore
      document.getElementById('sub').checked = true;
    } else if (this.isSubClicked === false) {
      this.registerForm.controls.isSubbed.setValue({isSubbed: false});
      // @ts-ignore
      document.getElementById('sub').checked = false;
    }
  }

  checkTermsValue(): void {
    if (this.termsIsClicked === true) {
      this.registerForm.controls.termsChecked.setValue({termsChecked: true});
      // @ts-ignore
      document.getElementById('radio-terms').checked = true;
    } else if (this.termsIsClicked === false) {
      this.registerForm.controls.termsChecked.setValue({termsChecked: false});
      // @ts-ignore
      document.getElementById('radio-terms').checked = false;
    }
  }

  checkPolicyValue(): void {
    if (this.policyIsClicked === true) {
      this.registerForm.controls.policyChecked.setValue({policyChecked: true});
      // @ts-ignore
      document.getElementById('radio-policy').checked = true;
    } else if (this.policyIsClicked === false) {
      this.registerForm.controls.policyChecked.setValue({policyChecked: false});
      // @ts-ignore
      document.getElementById('radio-policy').checked = false;
    }
  }

  getRadioValues(buttonId): boolean {
    // @ts-ignore
    return document.getElementById(buttonId).checked;
  }

  register(): void {
    if (this.isSubmitted || this.registerForm.status !== 'VALID' || !this.getRadioValues('radio-policy') || !this.getRadioValues('radio-terms')) {
      return;
    }

    if (this.isSubClicked) {
      // tslint:disable-next-line:max-line-length
      this.service.subscribeMail(this.registerForm.controls.email.value, 1, 1, this.registerForm.controls.firstname.value).subscribe(response => {
      });
    }
    this.isSubmitted = true;
    const email = this.registerForm.controls.email.value;
    const firstname = this.registerForm.controls.firstname.value;
    const lastname = this.registerForm.controls.lastname.value;
    const password = this.registerForm.controls.password.value;
    const confirm = this.registerForm.controls.confirm.value;
    /*register(firstname, lastname, email, password, confirm, terms, privacy):*/
    this.service.register(firstname, lastname, email, password, confirm, 1, 1).subscribe(response => {
      //console.log('STATUS', response);
      this.isSubmitted = false;
      if (response.status === 0) {
        const anjaAlert = Swal.mixin({
          customClass: {
            title: 'anja-alert-title',
            confirmButton: 'anja-alert-button',
          }
        });

        anjaAlert.fire({
          icon: 'success',
          title: this.translate.instant('REGISTRATION.REGISTRATION_SUCCESSFUL'),
          toast: false,
          focusConfirm: false,
          position: 'center',
          showConfirmButton: true,
          confirmButtonColor: '#3D3D3D',
          iconColor: '#0bbf74a6',
          confirmButtonText: this.translate.instant('LOGIN.OKAY'),
        });

        if (this.service.goPaymentAfterLogin) {
          this.service.goPaymentAfterLogin = false;
          this.router.navigate(['payment/personal-data']);
          this.service.showBasket = false;
        } else if (this.service.routeAfterLogin !== '') {
          setTimeout(() => {
            this.router.navigate([this.service.routeAfterLogin]);
          }, 2000);
        } else {
          this.router.navigate(['/']);
        }

        this.registerForm.setValue({
          firstname: '',
          lastname: '',
          password: '',
          confirm: '',
          email: '',
          policyChecked: false,
          termsChecked: false,
        });
        this.isSubmitted = false;

      } else if (response.status === 1) {
        const anjaAlert = Swal.mixin({
          customClass: {
            title: 'anja-alert-title',
            confirmButton: 'anja-alert-button',
          }
        });
        anjaAlert.fire({
          toast: false,
          position: 'center',
          icon: 'error',
          title: this.translate.instant('REGISTRATION.REGISTRATION_FAILED'),
          text: response.error.errors.warning,
          showConfirmButton: true,
          iconColor: '#E7B2AB',
          confirmButtonText: this.translate.instant('LOGIN.OKAY'),
          confirmButtonColor: '#3D3D3D',
        });
      }
      this.isSubmitted = false;
    }, error => {
      this.service.refreshToken(this.persistance.get('logged_in_user'));
    });
  }
}
