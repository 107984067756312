import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AppService} from '../app.service';
import {PersistanceService} from '../persistance.service';
import {TranslateService} from '@ngx-translate/core';
import Swal from 'sweetalert2';
import {BasketComponent} from '../basket/basket.component';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {

  product: any;
  attributes: any;
  selectedAttribute = 'Leírás';
  productOption: any;
  options = [];
  defaultSelectedOption: any;
  productValues = [];
  selectedImage = 0;
  selectedVideo = 0;
  images = [];
  videos = [];
  basketItemNumber: number;
  productCounter = 1;
  mediaType: any;

  wishlist: boolean;

  constructor(
    private route: ActivatedRoute,
    private service: AppService,
    private persistance: PersistanceService,
    private basket: BasketComponent,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    window.scrollTo({
      top: 0,
      left: 0,
      // @ts-ignore
      behavior: 'instant',
    });

    this.route.params.subscribe(params => {
        this.service.showProduct(params['product-slug']).subscribe(product => {
          this.product = product;
          this.wishlist = product.data.wishlist;

          this.attributes = this.product.data.attributes;
          this.productOption = this.product.data.options;
          if(this.product.data.attributes && this.product.data.attributes[0]){
            this.selectedAttribute = this.product.data.attributes[0].name
          }


          Object.values(this.product.data.options).forEach(option => {
            this.options.push(option);
          });

          Object.values(this.product.data.images.videos).forEach(video => {
            //console.log('VIDEO FILE', video);
            this.videos.push(video);
          });

          Object.values(this.product.data.images.product_images).forEach(image => {
            this.mediaType = 'img';
            this.images.push(image);
          });
          this.options.forEach(option => {
            option.product_option_value.forEach(value => {
              this.productValues.push(value);
            });
          });
          this.defaultSelectedOption = this.getLargestOption();
          // //console.log(this.defaultSelectedOption);
        }, error => {
          this.service.refreshToken(this.persistance.get('logged_in_user'));
        });
      }
    );
  }

  showImage(): void {
    return this.images && this.images[this.selectedImage] && this.images[this.selectedImage].sources.webp;
  }

  showVideo(): void {
    return this.videos && this.videos[this.selectedVideo] && this.videos[this.selectedVideo];
    this.getType(this.videos && this.videos[this.selectedVideo] && this.videos[this.selectedVideo]);

  }

  getLargestOption(): any {
    let tempProductValue = {};
    let tempValue = 0;

    this.productValues.forEach(value => {
      if (Number(value.name.split(' ')[0]) > tempValue) {
        tempValue = Number(value.name.split(' ')[0]);
        tempProductValue = value;
      }
    });
    return tempProductValue;
  }

  // tslint:disable-next-line:typedef
  getDiscountPrice(price) {
    const discountPrice = (Number(price.split(' ')[0]) * (this.product.data.formatted_prices.discount_percentage / 100));
    return (Number(price.split(' ')[0]) - discountPrice).toFixed(3);
  }

  modifyBasketQuantity(expression, quantity): number {
    if (expression === 'inc') {
      return quantity + 1;
    } else if (expression === 'dec') {
      const tempResult = quantity - 1;
      if (tempResult <= 0) {
        return 0;
      } else {
        return tempResult;
      }
    }
  }

  addToBasket(): void {
    for (let i = 0; i < this.productCounter; i++) {
      this.basketItemNumber = i + 1;
      // tslint:disable-next-line:max-line-length
      let optionId = 0;
      let valueId = 0;
      // tslint:disable-next-line:max-line-length
      if (this.product && this.product.data && this.product.data.options[0] && this.product.data.options[0].product_option_id && this.defaultSelectedOption.product_option_value_id) {
        optionId = this.product.data.options[0].product_option_id;
        valueId = this.defaultSelectedOption.product_option_value_id;
      }
      if (!this.persistance.get('api_token') || this.persistance.get('api_token') === 'undefined') {
        this.service.guestToken().subscribe(tokenResponse => {
          this.persistance.set('api_token', tokenResponse.data.api_token);
          this.addToCart(optionId, valueId);
        });
      } else {
        this.addToCart(optionId, valueId);
      }

    }
    this.productCounter = 1;
  }

  // ToDo: sweetalerts on response
  // ToDo: if user is not logged in store the wishlist on the localstorage. After login the wishlist should be updated for the user.
  addToWishlist(wishlist): void {
    if (!wishlist) {
      this.service.addToWishlist(this.product.data.product_id).subscribe(response => {
        this.wishlist = true;
      }, error => {
        this.service.refreshToken(this.persistance.get('logged_in_user'));
      });
    } else {
      this.service.removeFromWishlist(this.product.data.product_id).subscribe(response => {
        //console.log(response);
        this.wishlist = false;
      }, error => {
        this.service.refreshToken(this.persistance.get('logged_in_user'));
      });
    }
  }

  getType(link): void {
    const types = new Map([['webp', 'img'], ['gif', 'img'], ['mp4', 'video'], ['3gp', 'video']]);
    const url = new URL(link);
    const extension = url.pathname.split('.')[1];
    this.mediaType = (types.get(extension));
  }

  addToCart(optionId, valueId): void {
    this.service.addCart(this.product.data.product_id, optionId, valueId).subscribe(() => {
      const fbImage = document.createElement('img');
      fbImage.style.display = 'none';
      fbImage.style.width = '1px';
      fbImage.style.height = '1px';
      fbImage.src = 'https://www.facebook.com/tr?id=265138295569123&ev=AddToCart&cd[currency]=HUF&cd[value]=1.00&time=' + Date.now();
      const element = document.getElementById('facebook-add-to-cart');
      element.appendChild(fbImage);
      this.service.getCart().subscribe(response => {
        Object.values(response.data.products).forEach(product => {
          this.basket.productsInBasket.push(product);
        });
      }, error => {
        this.service.refreshToken(this.persistance.get('logged_in_user'));
      });
      const anjaAlert = Swal.mixin({
        customClass: {
          title: 'anja-alert-title',
          confirmButton: 'anja-alert-button',
        }
      });

      anjaAlert.fire({
        icon: 'success',
        title: this.translate.instant('BASKET.ADDED_TO_BASKET_1') + ' ' + this.basketItemNumber + ' '
          + this.translate.instant('BASKET.ADDED_TO_BASKET_2'),
        toast: true,
        timerProgressBar: true,
        timer: 3000,
        position: 'center',
        focusConfirm: false,
        showConfirmButton: true,
        confirmButtonColor: '#3D3D3D',
        iconColor: '#0bbf74a6',
        confirmButtonText: this.translate.instant('LOGIN.OKAY'),
      });
    }, error => {
      this.service.refreshTokenThenAddProduct(this.persistance.get('logged_in_user'), this.product.data.product_id, optionId, valueId);
    });
  }

}





