<section class="container-wrapper">
  <div class="logo-wrapper">
    <img routerLink="/" class="logo" src="assets/images/top-logo.svg">
  </div>


  <section class="container">

    <h1 class="title">
      {{"TERMS.TITLE" | translate}}
    </h1>
    <h3 (click)="back()" class="back">{{"MENU.BACK" | translate}}</h3>

    <p>
      <br/>
      {{"TERMS.1" | translate}}
      <br/>
      <br/>
      {{"TERMS.1.1" | translate}}
      <br/>
      <br/>
      {{"TERMS.INFO" | translate}}
      <br/>
      {{"TERMS.FIRM" | translate}}
      <br/>
      {{"TERMS.HQ" | translate}}
      <br/>
      {{"TERMS.CONTACT" | translate}}
      <br/>
      {{"TERMS.PHONE" | translate}}
      <br/>
      {{"TERMS.EMAIL" | translate}}
      <br/>
      {{"TERMS.WEB" | translate}}
      <br/>
      {{"TERMS.TAX" | translate}}
      <br/>
      {{"TERMS.FIRM_NUMBER" | translate}}
      <br/>
      {{"TERMS.MORE_INFO" | translate}}
      <br/>
      <br/>
      {{"TERMS.1.2" | translate}}
      <br/>
      <br/>
      {{"TERMS.1.3" | translate}}
      <br/>
      <br/>
      {{"TERMS.1.4" | translate}}
      <br/>
      <br/>
      {{"TERMS.1.5" | translate}}
      <br/>
      <br/>
      {{"TERMS.2" | translate}}
      <br/>
      <br/>
      {{"TERMS.2.1" | translate}}
      <br/>
      <br/>
      {{"TERMS.2.11" | translate}}
      <br/>
      <br/>
      {{"TERMS.2.12" | translate}}
      <br/>
      <br/>
      {{"TERMS.2.13" | translate}}
      <br/>
      <br/>
      <br/>
      {{"TERMS.2.2" | translate}}
      <br/>
      <br/>
      {{"TERMS.2.21" | translate}}
      <br/>
      <br/>
      {{"TERMS.2.22" | translate}}
      <br/>
      <br/>
      {{"TERMS.2.3" | translate}}
      <br/>
      <br/>
      {{"TERMS.2.31" | translate}}
      <br/>
      <br/>
      {{"TERMS.2.4" | translate}}
      <br/>
      <br/>
      {{"TERMS.2.41" | translate}}
      <br/>
      <br/>
      {{"TERMS.2.42" | translate}}
      <br/>
      <br/>
      {{"TERMS.2.43" | translate}}
      <br/>
      <br/>
      {{"TERMS.2.44" | translate}}
      <br/>
      <br/>
      <strong>
        {{"TERMS.2.45" | translate}}
      </strong>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      {{"TERMS.2.5" | translate}}
      <br/>
      <br/>
      {{"TERMS.2.51" | translate}}
      <br/>
      <br/>
      {{"TERMS.2.52" | translate}}
      <br/>
      <br/>
      {{"TERMS.2.53" | translate}}
      <br/>
      <br/>
      {{"TERMS.2.54" | translate}}
      <br/>
      <br/>
      {{"TERMS.2.55" | translate}}
      <br/>
      <br/>
      {{"TERMS.2.56" | translate}}
      <br/>
      <br/>
      {{"TERMS.2.57" | translate}}
      <br/>
      <br/>
      {{"TERMS.2.6" | translate}}
      <br/>
      <br/>
      {{"TERMS.2.61" | translate}}
      <br/>
      <br/>
      {{"TERMS.2.62" | translate}}
      <br/>
      <br/>
      <br/>
    </p>
    <p>
      {{"TERMS.3" | translate}}
      <br/>
      <br/>
      {{"TERMS.3.1" | translate}}
      <br/>
      <br/>
      <br/>
      {{"TERMS.3.11" | translate}}
      <br/>
      <br/>
      <br/>
    </p>
    <p>
      {{"TERMS.3.2" | translate}}
      <br/>
      <br/>
      {{"TERMS.3.21" | translate}}
      <br/>
      <br/>
      {{"TERMS.3.22" | translate}}
      <br/>
      <br/>
      {{"TERMS.3.23" | translate}}
      <br/>
      <br/>
      {{"TERMS.3.24" | translate}}
      <br/>
      <br/>
      {{"TERMS.3.25" | translate}}
      <br/>
      <br/>
      {{"TERMS.3.26" | translate}}
      <br/>
      <br/>
      {{"TERMS.3.27" | translate}}
      <br/>
      <br/>
      {{"TERMS.3.28" | translate}}
      <br/>
      <br/>
      {{"TERMS.3.29" | translate}}
      <br/>
      <br/>
      <br/>
      {{"TERMS.4" | translate}}
      <br/>
      <br/>
      {{"TERMS.41" | translate}}
      <br/>
      <br/>
      {{"TERMS.42" | translate}}
      <br/>
      <br/>
      <br/>
      {{"TERMS.43" | translate}}
      <br/>
      <br/>
      {{"TERMS.44" | translate}}

    </p>
    <p>
      <br/>
      {{"TERMS.451" | translate}}
      <br/>
      {{"TERMS.452" | translate}}
      <br/>
      <br/>
      {{"TERMS.461" | translate}}
      <br/>
      {{"TERMS.462" | translate}}
    </p>
    <p>
      <br/>
      {{"TERMS.471" | translate}}
      <br/>
      {{"TERMS.472" | translate}}
      <br/>
      <br/>
      {{"TERMS.48" | translate}}
      <br/>
      <br/>
      {{"TERMS.49" | translate}}
      <br/>
      <br/>
      {{"TERMS.410" | translate}}
      <br/>
      <br/>
      {{"TERMS.5" | translate}}
      <br/>
      <br/>
      {{"TERMS.51" | translate}}
      <br/>
      <br/>
      {{"TERMS.52" | translate}}
      <br/>
      <br/>
      {{"TERMS.53" | translate}}
      <br/>
      <br/>
      {{"TERMS.6" | translate}}
      <br/>
      <br/>
      {{"TERMS.61" | translate}}
      <br/>
      <br/>
      {{"TERMS.62" | translate}}
      <br/>
      <br/>
      <br/>
      {{"TERMS.63" | translate}}
    </p>

  </section>
  <h3 (click)="back()" class="back padding-bottom">{{"MENU.BACK" | translate}}</h3>
</section>
