import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from './src/app/main/main.component';
import {LoginComponent} from './src/app/login/login.component';
import {ProductListComponent} from './src/app/product-list/product-list.component';
import {ProductComponent} from './src/app/product/product.component';
import {CheckoutComponent} from './src/app/checkout/checkout.component';
import {PaymentComponent} from './src/app/payment/payment.component';
import {BlogsComponent} from './src/app/blogs/blogs.component';
import {BlogComponent} from './src/app/blog/blog.component';
import {CategoryComponent} from './src/app/category/category.component';
import {OurValuesComponent} from './src/app/our-values/our-values.component';
import {AboutUsComponent} from './src/app/about-us/about-us.component';
import {RegisterComponent} from './src/app/register/register.component';
import {ComingSoonComponent} from './src/app/coming-soon/coming-soon.component';
import {PolicyComponent} from './src/app/policy/policy.component';
import {TermsComponent} from './src/app/terms/terms.component';
import {ContactUsComponent} from './src/app/contact-us/contact-us.component';
import {CookieComponent} from './src/app/cookie/cookie.component';
import {LandingComponent} from './src/app/landing/landing.component';
import {CongratsComponent} from './src/app/congrats/congrats.component';
import {ErrorComponent} from './src/app/error/error.component';
import {NotFoundComponent} from './src/app/not-found/not-found.component';
import {CancelComponent} from './src/app/cancel/cancel.component';
import {ModifyPasswordComponent} from './src/app/modify-password/modify-password.component';
import {SubscribeComponent} from './src/app/subscribe/subscribe.component';
import {SimplepayErrorComponent} from './src/app/simplepay-error/simplepay-error.component';

// ToDo: routing in hungarian?
const routes: Routes = [
  {path: '', component: MainComponent},
  {path: 'policy', component: PolicyComponent},
  {path: 'terms', component: TermsComponent},
  {path: 'category/:category', component: CategoryComponent},
  {path: 'our-mission', component: AboutUsComponent},
  {path: 'login', component: LoginComponent},
  {path: 'products', component: ProductListComponent},
  {path: 'product/:product-slug', component: ProductComponent},
  {path: 'checkout', component: CheckoutComponent},
  {path: 'payment/:step', component: PaymentComponent},
  {path: 'blog', component: BlogsComponent},
  {path: 'blog/:seo-url', component: BlogComponent},
  {path: 'our-values/:value', component: OurValuesComponent},
  {path: 'registration', component: RegisterComponent},
  {path: 'coming-soon', component: ComingSoonComponent},
  {path: 'contact', component: ContactUsComponent},
  {path: 'payment/cancelled/:orderId', component: CancelComponent},
  {path: 'payment/success/:id', component: CongratsComponent},
  {path: 'payment/error/:id/:orderId', component: ErrorComponent},
  {path: 'simplepay/error/:id', component: SimplepayErrorComponent},
  {path: 'account/reset/:code', component: ModifyPasswordComponent},
  {path: 'cookie-policy', component: CookieComponent},
  {path: 'magic-oil', component: LandingComponent},
  {path: '404', component: NotFoundComponent},
  {path: ':subscribe', component: MainComponent},
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
