import {Component, OnInit} from '@angular/core';
import {AppService} from '../app.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-congrats',
  templateUrl: './congrats.component.html',
  styleUrls: ['./congrats.component.css']
})
export class CongratsComponent implements OnInit {

  public transactionId: string;
  private orderItems = [];

  constructor(
    private router: Router,
    private service: AppService
  ) {
    this.transactionId = this.router.url.split('/').pop();
  }


  async ngOnInit(): Promise<void> {

    (window as any).gtag('config', 'G-0M8ENQEE94');

    const orderInfo = await this.service.getOrder(this.transactionId);
    // const orderItems = this.getOrderItems(orderInfo.items);
    (window as any).gtag('event', 'purchase', {
      transaction_id: this.transactionId,
      // affiliation: 'Google Merchandise Store',
      value: orderInfo.order.total,
      // tax: 4.90,
      // shipping: 5.99,
      currency: orderInfo.order.currency_code,
      // coupon: 'SUMMER_SALE',
      items: orderInfo.items
      // items: [
      //   {
      //     item_id: 'SKU_12345',
      //     item_name: 'Stan and Friends Tee',
      //     // affiliation: 'Google Merchandise Store',
      //     // coupon: 'SUMMER_FUN',
      //     currency: orderInfo.order.currency_code,
      //     // discount: 2.22,
      //     index: 0,
      //     // item_brand: 'Google',
      //     // item_category: 'Apparel',
      //     // item_category2: 'Adult',
      //     // item_category3: 'Shirts',
      //     // item_category4: 'Crew',
      //     // item_category5: 'Short sleeve',
      //     // item_list_id: 'related_products',
      //     // item_list_name: 'Related Products',
      //     // item_variant: 'green',
      //     // location_id: 'L_12345',
      //     price: 9.99,
      //     quantity: 1
      //   },
      //   {
      //     item_id: 'SKU_12346',
      //     item_name: 'Google Grey Women\'s Tee',
      //     affiliation: 'Google Merchandise Store',
      //     coupon: 'SUMMER_FUN',
      //     currency: 'USD',
      //     discount: 3.33,
      //     index: 1,
      //     item_brand: 'Google',
      //     item_category: 'Apparel',
      //     item_category2: 'Adult',
      //     item_category3: 'Shirts',
      //     item_category4: 'Crew',
      //     item_category5: 'Short sleeve',
      //     item_list_id: 'related_products',
      //     item_list_name: 'Related Products',
      //     item_variant: 'gray',
      //     location_id: 'L_12345',
      //     price: 20.99,
      //     promotion_id: 'P_12345',
      //     promotion_name: 'Summer Sale',
      //     quantity: 1
      //   }]
    });

    this.jumpTop();
  }

  jumpTop(): void {
    // @ts-ignore
    window.scrollTo({top: 0, behavior: 'instant'});
  }

  // getOrderItems(items) {
  //   items.forEach()
  //   return []
  // }
}
