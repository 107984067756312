<section class="subscribe-wrapper">
  <section class="subscribe-section container">
    <p class="title-small">{{"SUBSCRIBE.KEEP_IN_TOUCH" | translate}}</p>
    <p class="title-large">{{"SUBSCRIBE.SUBSCRIBE" | translate}}</p>
    <form [formGroup]="subscribeForm">
      <p class="input-span">{{"SUBSCRIBE.FIRST_NAME" | translate}}</p>
      <input class="input-field" type="text" id="name" name="name"
             formControlName="name" placeholder="{{'SUBSCRIBE.NAME_PLACEHOLDER' | translate}}" required>
      <p class="input-span">{{"SUBSCRIBE.EMAIL" | translate}}</p>
      <input class="input-field" type="email" id="email" name="email" email
             formControlName="email" placeholder="{{'SUBSCRIBE.EMAIL_PLACEHOLDER' | translate}}" required>
    </form>

    <p class="input-span">{{"SUBSCRIBE.LANGUAGE" | translate}}</p>
    <select id="lang-selector" class="input-field input-select" [(ngModel)]="selectedLanguage">
      <option *ngFor="let lang of service.lang" [ngValue]="lang.code">{{ 'LANG.' + lang.code | translate}}</option>
    </select>

    <form [formGroup]="subscribeForm">
      <br>
      <section class="radio-wrapper">
        <input type="radio" id="radio-policy" formControlName="policyChecked" ngModel (click)="togglePolicyButton()">
        <label for="radio-policy">{{'SUBSCRIBE.THE' | translate}}<a class="link" routerLink="/policy"
                                                                    href="">{{'SUBSCRIBE.POLICY' |
          translate}}</a>{{'SUBSCRIBE.UNDERSTOOD_AND_ACCEPTED' | translate}}
        </label>
      </section>

      <section class="radio-wrapper">
        <input type="radio" id="radio-terms" formControlName="termsChecked" ngModel (click)="toggleTermsButton()">
        <label for="radio-terms">{{'SUBSCRIBE.ACCEPT' | translate}}<a class="link" routerLink="/terms"
                                                                      href="">{{'SUBSCRIBE.ASZF' |
          translate}}</a></label>
      </section>

      <button (click)="subscribeMail()"
              class="button"
              [disabled]="isSubmitted || subscribeForm.status !== 'VALID' || !getRadioValues('radio-policy')  || !getRadioValues('radio-terms')">
        <img *ngIf="isSubmitted" class="spinner" src="assets/images/loading.gif" alt="Loading Spinner">
        {{'BUTTON.SUBSCRIBE' | translate}}

      </button>
    </form>
    <img *ngIf="lang === 'hu-hu'" class="stamp col-12" src="assets/images/szlogen.svg" alt="szlogen">
    <img *ngIf="lang === 'en-gb'" class="stamp col-12" src="assets/images/szlogen_en.svg" alt="motto">
  </section>
</section>

<!--{{this.subscribeForm.get('policyChecked').value}}-->

