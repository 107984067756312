<section class="ultra-container container ">
  <section class="mega-container container">
    <section class="bg-wrapper container">
      <section class="row justify-content-center">
        <section class="contact-container row justify-content-center">
          <section class="container">
            <h1 class="contact-title">{{"ERROR.TITLE" | translate}}</h1>

<!--            <section *ngIf="transactionId !== '0'">-->
<!--              <p class="contact-text">{{"ERROR.TRANSACTION_ID" | translate}} {{transactionId}}</p>-->
<!--              <button class="button" (click)="startPayment()">Fizetés újraindítása</button>-->
<!--              <br><br>-->
<!--            </section>-->

            <p class="contact-text">{{"ERROR.SIMPLEPAY" | translate}}</p>
            <br>
            <p class="contact-text">{{"ERROR.SIMPLEPAY_CODES" | translate}} {{errorCodes}}</p>
            <br>
            <p class="contact-text">{{"ERROR.ORDER_ID" | translate}} {{orderId}}</p>
            <br>
            <p class="contact-text">{{"ERROR.SIMPLEPAY_PLS_CONTACT" | translate}}</p>
            <p class="contact-text">{{"TERMS.EMAIL" | translate}}</p>
            <p class="contact-text">{{"TERMS.PHONE" | translate}}</p>
            <img src="../../assets/images/anja_sign.svg">
          </section>
        </section>
      </section>
    </section>
  </section>
</section>
