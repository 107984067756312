import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AppService} from '../app.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {
  public test = ``;
  @ViewChild('someVar') el: ElementRef;
  blog: any;
  relatedProducts = [];

  constructor(
    private route: ActivatedRoute,
    private service: AppService
  ) {
  }

  ngOnInit(): void {
    window.scrollTo({
      top: 0,
      left: 0,
      // @ts-ignore
      behavior: 'instant',
    });

    this.route.params.subscribe(params => {
      this.service.getBlog(params['seo-url']).subscribe(blog => {
        this.blog = blog;
        this.test = blog && blog.data.post.text;
        this.relatedProducts = this.blog.data.post.products;
      });
    });
  }

  getProductSeo(seoUrl): string {
    return seoUrl.split(';').pop();
  }
}
