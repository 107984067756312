import {Component, OnInit} from '@angular/core';
import {AppService} from '../app.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-related-products',
  templateUrl: './related-products.component.html',
  styleUrls: ['./related-products.component.css']
})
export class RelatedProductsComponent implements OnInit {

  product: any;
  relatedProducts = [];
  relatedProductList = [];
  relatedProductImages = [];

  constructor(
    private service: AppService,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.service.showRelatedProducts(params['product-slug']).subscribe(product => {
        this.product = product;
        Object.values(this.product.data.related_products).forEach(relatedProduct => {
          this.relatedProducts.push(relatedProduct);
        });
      });
    });
  }
  getProductSeo(seoUrl): void {
    return seoUrl.split(';').pop();
  }
}
