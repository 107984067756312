import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { PersistanceService } from '../persistance.service';
import { first, map, startWith } from 'rxjs/operators';
import { AppService } from '../app.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private service: AppService,
    private translate: TranslateService,
    private router: Router,
    private persistance: PersistanceService,
  ) {
  }

  personalDataForm = new FormGroup({
    firstname: new FormControl('', Validators.required),
    lastname: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    address_2: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),
    zone_id: new FormControl('', Validators.required),
    irszam: new FormControl('', Validators.required),
    cim: new FormControl('', Validators.required),
    hazszam: new FormControl('', Validators.required),
    egyebhazinfo: new FormControl('', Validators.required),
    // message to currier is hidden due to request
    // komment: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    country_id: new FormControl('', Validators.required),

    billingFirstname: new FormControl('', Validators.required),
    billingLastname: new FormControl('', Validators.required),
    billingEmail: new FormControl('', Validators.required),
    billingAddress_2: new FormControl('', Validators.required),
    billingPhone: new FormControl('', Validators.required),
    billingZone_id: new FormControl('', Validators.required),
    billingIrszam: new FormControl('', Validators.required),
    billingCim: new FormControl('', Validators.required),
    billingHazszam: new FormControl('', Validators.required),
    billingEgyebhazinfo: new FormControl('', Validators.required),
    billingCity: new FormControl('', Validators.required),
    billingCountry_id: new FormControl('', Validators.required),
    companyName: new FormControl('', Validators.required),
    companyVAT: new FormControl('', Validators.required),
    policyChecked: new FormControl('false'),
    termsChecked: new FormControl('false'),
    differentBillingAddress: new FormControl('false'),
    companyBilling: new FormControl('false'),
    isSubbed: new FormControl('true'),
  });

  shippingForm = new FormGroup({
    shipping: new FormControl('gls'),
    simplepay: new FormControl('false'),
    cod: new FormControl('false'),
    selectedTimeWindow: new FormControl(''),

  });

  // simplePayForm = new FormGroup({
  //   simplePolicyChecked: new FormControl('false'),
  // });

  isSubmitted: boolean = false;
  dot: boolean = true;
  routeState = '';

  personalData = 1;
  summary = 0;
  shippingAndPayment = 0;

  companyBillingIsClicked: boolean = false;
  differentBillingAddressIsClicked: boolean = false;
  isSubClicked: boolean = true;
  isShipping1Clicked: boolean = true;
  isSimplePayClicked: boolean = false;
  isCodClicked: boolean = false;

  //// Reusable info

  costumerFirstName: any;
  costumerLastName: any;
  costumerName: any;
  costumerEmail: any;
  costumerPhone: any;
  costumerAddress: any;
  costumerOther: any;
  costumerComment: any;
  costumerShippingMethod: any;
  costumerPaymentMethod: any;

  /// Redirect URL after an order has been successfully sent

  simplePayURL: any;

  selectedPaymentMethod: any;
  selectedShippingMethod: any;
  isSimplePolicyChecked: boolean = false;

  policy = false;
  termsAndConditions = false;
  policyIsClicked: boolean = false;
  termsIsClicked: boolean = false;
  billingFirstname = '';
  billingLastname = '';
  gls_default: any;
  gls_point: any;
  gls_point_cod: any
  gls_preferred_time_window: any
  gls_preferred_time_window_cod: any
  gls_cash_delivery: any;
  gls_point_code: any
  gls_point_title: string
  gls_point_details: {
    country: string,
    zipcode: string,
    city: string,
    address: string,
    name: string,
    code: string,
  }
  showGlsPointMap: boolean = false
  timeWindows: Array<{text: string, value: string}> = [
    {text: '08:00 - 10:00', value: '08:00 - 10:00'},
    {text: '10:00 - 12:00', value: '10:00 - 12:00'},
    {text: '12:00 - 14:00', value: '12:00 - 14:00'},
    {text: '14:00 - 16:00', value: '14:00 - 16:00'},
    {text: '16:00 - 18:00', value: '16:00 - 18:00'},
  ]

  shippingData: {
    firstName: string,
    lastName: string,
    email: string,
    address1: string,
    address2: string,
    city: string,
    countryId: number,
    phone: string,
  }

  billingData: {
    firstName: string,
    lastName: string,
    email: string,
    address1: string,
    address2: string,
    city: string,
    countryId: number,
  }

  step01ImgSrc: string;
  step02ImgSrc: string;

  simple_pay: any;
  cod_payment: any;
  errorMessages: any;
  control = new FormControl();
  hasShipping: boolean = false
  errors = [];

  couponCode = '';
  coupon = '';
  couponOff = '';

  showCouponCodeInput = false;

  anjaAlert = Swal.mixin({
    customClass: {
      title: 'anja-alert-title',
      confirmButton: 'anja-alert-button',
      cancelButton: 'anja-cancel-button',
    }
  });

  ///////////////////// Összesítő

  ngOnInit(): void {

    this.scrollTop();

    this.checkForCoupon();
    this.route.params.subscribe(params => {

      if (this.routeState === 'shipping-and-payment') {
        this.getShippingData();
      }

      this.routeState = params.step;
      if (this.routeState === 'shipping-and-payment') {
        this.getShippingData();
      }

      if (this.routeState === 'shipping-and-payment') {
        this.step01ImgSrc = 'assets/images/step_ok.svg';
        this.step02ImgSrc = 'assets/images/number_02.svg';
      } else if (this.routeState === 'summary') {
        this.step01ImgSrc = 'assets/images/step_ok.svg';
        this.step02ImgSrc = 'assets/images/step_ok.svg';
      } else {
        this.step01ImgSrc = 'assets/images/number_01.svg';
        this.step02ImgSrc = 'assets/images/number_02.svg';
      }
    });
    if (this.persistance.get('logged_in_user') === 'true') {
      this.service.getAccountData().subscribe(response => {
        const addressName = response.data.addresses[0].address_1.replace(/[0-9]/g, '');
        const addressNumber = response.data.addresses[0].address_1.split(' ')[response.data.addresses[0].address_1.split(' ').length - 1];

        this.personalDataForm.controls.firstname.setValue(response.data.profile.firstname);
        this.personalDataForm.controls.lastname.setValue(response.data.profile.lastname);
        this.personalDataForm.controls.email.setValue(response.data.profile.email);
        this.personalDataForm.controls.phone.setValue(response.data.profile.telephone);
        this.personalDataForm.controls.irszam.setValue(response.data.addresses[0].postcode);
        this.personalDataForm.controls.city.setValue(response.data.addresses[0].city);
        this.personalDataForm.controls.cim.setValue(addressName);
        this.personalDataForm.controls.hazszam.setValue(addressNumber);
        this.personalDataForm.controls.address_2.setValue(response.data.addresses[0].address_2);
      });
    }
  }

  hasShippingFn(): boolean {
    return this.hasShipping
  }

  toggleRadioButton(toggleValue, buttonId): void {
    this[toggleValue] = !this[toggleValue];
    if (this[toggleValue]) {
      // @ts-ignore
      document.getElementById(buttonId).checked = true;
    } else if (!this[toggleValue]) {
      // @ts-ignore
      document.getElementById(buttonId).checked = false;
    }
  }

  toggleSubButton(): void {
    this.isSubClicked = !this.isSubClicked;
    this.checkSubscribe();
  }

  checkSubscribe(): void {
    if (this.isSubClicked === true) {
      this.personalDataForm.controls.isSubbed.setValue({ isSubbed: true });
      // @ts-ignore
      document.getElementById('sub').checked = true;
    } else if (this.isSubClicked === false) {
      this.personalDataForm.controls.isSubbed.setValue({ isSubbed: false });
      // @ts-ignore
      document.getElementById('sub').checked = false;
    }
  }

  toggleTermsButton(): void {
    this.termsIsClicked = !this.termsIsClicked;
    this.checkTermsValue();
  }

  togglePolicyButton(): void {
    this.policyIsClicked = !this.policyIsClicked;
    this.checkPolicyValue();
  }

  toggleDifferentBillButton(): void {
    this.differentBillingAddressIsClicked = !this.differentBillingAddressIsClicked;
    this.checkDiffBilling();
  }

  toggleCompanyButton(): void {
    this.companyBillingIsClicked = !this.companyBillingIsClicked;
    this.checkCompany();
  }

  checkDiffBilling(): void {
    if (this.differentBillingAddressIsClicked === true) {
      this.personalDataForm.controls.differentBillingAddress.setValue({ differentBillingAddress: true });
      // @ts-ignore
      document.getElementById('different-billing-address').checked = true;
    } else if (this.differentBillingAddressIsClicked === false) {
      this.personalDataForm.controls.differentBillingAddress.setValue({ differentBillingAddress: false });
      // @ts-ignore
      document.getElementById('different-billing-address').checked = false;
    }
  }

  checkCompany(): void {
    if (this.companyBillingIsClicked === true) {
      this.personalDataForm.controls.companyBilling.setValue({ companyBilling: true });
      // @ts-ignore
      document.getElementById('company-billing-address').checked = true;
    } else if (this.companyBillingIsClicked === false) {
      this.personalDataForm.controls.companyBilling.setValue({ companyBilling: false });
      // @ts-ignore
      document.getElementById('company-billing-address').checked = false;
    }
  }

  checkTermsValue(): void {
    if (this.termsIsClicked === true) {
      this.personalDataForm.controls.termsChecked.setValue({ termsChecked: true });
      // @ts-ignore
      document.getElementById('radio-terms').checked = true;
    } else if (this.termsIsClicked === false) {
      this.personalDataForm.controls.termsChecked.setValue({ termsChecked: false });
      // @ts-ignore
      document.getElementById('radio-terms').checked = false;
    }
  }

  checkPolicyValue(): void {
    if (this.policyIsClicked === true) {
      this.personalDataForm.controls.policyChecked.setValue({ policyChecked: true });
      // @ts-ignore
      document.getElementById('radio-policy').checked = true;
    } else if (this.policyIsClicked === false) {
      this.personalDataForm.controls.policyChecked.setValue({ policyChecked: false });
      // @ts-ignore
      document.getElementById('radio-policy').checked = false;
    }
  }


  getBillingFirstName() {
    /* company billing */
    if (this.companyBillingIsClicked) {
      return 'Cégnév: ' + this.personalDataForm.controls.companyName.value + ' ' + 'Adószám: ' + this.personalDataForm.controls.companyVAT.value
    }

    /* not company billing */
    return this.shippingData.firstName

  }

  getBillingLastName() {
    /* company billing */
    if (this.companyBillingIsClicked) {
      return this.personalDataForm.controls.lastname.value + ' ' + this.personalDataForm.controls.firstname.value;
    }
    /* not company billing */
    return this.personalDataForm.controls.lastname.value
  }

  getBillingAddress1() {
    /* company billing */
    if (this.differentBillingAddressIsClicked) {
      return this.personalDataForm.controls.billingIrszam.value + ' ' + this.personalDataForm.controls.billingCity.value + ' ' + this.personalDataForm.controls.billingCim.value +
        ' ' + this.personalDataForm.controls.billingHazszam.value
    }

    /* not company billing */
    return this.personalDataForm.controls.irszam.value + ' ' + this.personalDataForm.controls.city.value + ' ' + this.personalDataForm.controls.cim.value +
      ' ' + this.personalDataForm.controls.hazszam.value
  }

  getBillingAddress2() {
    /* company billing */
    if (this.differentBillingAddressIsClicked) {
      return this.personalDataForm.controls.billingAddress_2.value;
    }

    /* not company billing */
    return this.personalDataForm.controls.address_2.value
  }

  getBillingCity() {
    /* company billing */
    if (this.differentBillingAddressIsClicked) {
      return this.personalDataForm.controls.billingCity.value;
    }

    /* not company billing */
    return this.personalDataForm.controls.city.value
  }

  private _setShippingData() {
    let address1 : string = ''
    let address2 : string = ''
    /* GLS CSOMAGPONT */
    if(this.isGlsPointShipping) {
      address1 = this.gls_point_details.zipcode + ' ' + this.gls_point_details.city + ', ' + this.gls_point_details.address
      address2 = this.gls_point_details.name + ' (' + this.gls_point_details.code + ')'
    } 
    /* GLS HÁZHOZSZÁLLÍTÁS - PREFERÁLT IDŐABLAKOS */
    else if(this.isGlsPreferredTimeWindow) {
      address1 = this.personalDataForm.controls.irszam.value + ' ' + this.personalDataForm.controls.city.value + ' ' + this.personalDataForm.controls.cim.value + ' ' + this.personalDataForm.controls.hazszam.value
      address2 = this.personalDataForm.controls.address_2.value + ' (' + 'Preferált szállítási időablak: ' + this.shippingForm.controls.selectedTimeWindow.value + ')';
    }
    /* EGYÉB */
    else {
      address1 = this.personalDataForm.controls.irszam.value + ' ' + this.personalDataForm.controls.city.value + ' ' + this.personalDataForm.controls.cim.value + ' ' + this.personalDataForm.controls.hazszam.value
      address2 = this.personalDataForm.controls.address_2.value 
    }
    /* shipping data */
    this.shippingData = {
      firstName: this.personalDataForm.controls.firstname.value,
      lastName: this.personalDataForm.controls.lastname.value,
      email: this.personalDataForm.controls.email.value,
      //address1: !this.isGlsPointShipping ? this.personalDataForm.controls.irszam.value + ' ' + this.personalDataForm.controls.city.value + ' ' + this.personalDataForm.controls.cim.value + ' ' + this.personalDataForm.controls.hazszam.value : this.gls_point_details.zipcode + ' ' + this.gls_point_details.city + ', ' + this.gls_point_details.address,
      //address2: !this.isGlsPointShipping ? this.personalDataForm.controls.address_2.value : this.gls_point_details.name + ' (' + this.gls_point_details.code + ')',
      address1 : address1, 
      address2 : address2,
      city: !this.isGlsPointShipping ? this.personalDataForm.controls.city.value : this.gls_point_details.city,
      countryId: 97,
      phone: this.personalDataForm.controls.phone.value,
    }
  }

  private _setBillingData() {
    /* billing data */
    this.billingData = {
      firstName: this.getBillingFirstName(),
      lastName: this.getBillingLastName(),
      email: this.personalDataForm.controls.email.value,
      address1: this.getBillingAddress1(),
      address2: this.getBillingAddress2(),
      city: this.getBillingCity(),
      countryId: 97,
    }
  }

  async alertHandler() {
    this._setShippingData();
    this._setBillingData();

    /* SUBSCRIBE TO NEWSLETTER */
    if (this.personalDataForm.controls.isSubbed.value === true || this.isSubClicked === true) {
      this.service.subscribeMail(this.shippingData.email, 1, 1, this.shippingData.lastName + ' ' + this.shippingData.firstName).subscribe(response => {
      }, error => {
      });
    }

    /* SETTING BILLING DATA */
    const billingRes = await this.service.setBilling(this.billingData.firstName, this.billingData.lastName, this.billingData.email, this.billingData.address1, this.billingData.address2, this.billingData.city, this.billingData.countryId);
    console.log(billingRes)
    /* if error happened */
    if (billingRes.status != 0 || this.getErrorsLength() > 0) {
      this.openAlert(billingRes);
      return
    }

    /* SETTING SHIPPING DATA */
    const shippingRes = await this.service.setShippingAddress(this.shippingData.firstName, this.shippingData.lastName, this.shippingData.email, this.shippingData.address1, this.shippingData.address2, this.shippingData.city, this.shippingData.countryId);
    console.log(shippingRes)
    /* if error happened */
    if (shippingRes.status != 0 || this.getErrorsLength() > 0) {
      this.openAlert(shippingRes);
      return
    }
    /* billing and shipping saved */
    this.redirect();
    return


    /*const firstname = this.personalDataForm.controls.firstname.value;
    const lastname = this.personalDataForm.controls.lastname.value;
    const name = this.personalDataForm.controls.firstname.value + ' ' + this.personalDataForm.controls.lastname.value;

    this.costumerName = this.personalDataForm.controls.firstname.value + ' ' + this.personalDataForm.controls.lastname.value;
    this.costumerEmail = this.personalDataForm.controls.email.value;
    // tslint:disable-next-line:max-line-length
    this.costumerAddress = this.personalDataForm.controls.irszam.value + ' ' + this.personalDataForm.controls.city.value + ' ' + this.personalDataForm.controls.cim.value +
      ' ' + this.personalDataForm.controls.hazszam.value;
    this.costumerPhone = this.personalDataForm.controls.phone.value;

    this.costumerOther = this.personalDataForm.controls.address_2.value;

    const email = this.personalDataForm.controls.email.value;
    const city = !this.isGlsPointShipping ?  this.personalDataForm.controls.city.value : this.gls_point_details.city;
    // tslint:disable-next-line:max-line-length
    const address_1 = !this.isGlsPointShipping  ? this.personalDataForm.controls.irszam.value + ' ' + this.personalDataForm.controls.city.value + ' ' + this.personalDataForm.controls.cim.value +
      ' ' + this.personalDataForm.controls.hazszam.value : this.gls_point_details.zipcode + ' ' + this.gls_point_details.city + ', ' + this.gls_point_details.address;
    const address_2 =  !this.isGlsPointShipping ? this.personalDataForm.controls.address_2.value : this.gls_point_details.name;
    const billingCity = this.personalDataForm.controls.billingCity.value;
    const billingAddress_1 = this.personalDataForm.controls.billingIrszam.value + ' ' + this.personalDataForm.controls.billingCity.value + ' ' + this.personalDataForm.controls.billingCim.value +
      ' ' + this.personalDataForm.controls.billingHazszam.value + ' '
      + this.personalDataForm.controls.billingAddress_2.value;
    const billingAddress_2 = this.personalDataForm.controls.billingAddress_2.value;

    const country_id = 97;

    if (this.personalDataForm.controls.isSubbed.value === true || this.isSubClicked === true) {
      this.service.subscribeMail(email, 1, 1, name).subscribe(response => {
      }, error => {
      });
    }

    if (this.companyBillingIsClicked === true) {
      this.costumerFirstName = this.billingFirstname;
      this.costumerLastName = this.billingLastname;
      this.costumerName = 'Cégnév: \n' + this.personalDataForm.controls.companyName.value + ' ' +
        // tslint:disable-next-line:max-line-length
        '\n\nAdószám: \n' + this.personalDataForm.controls.companyVAT.value + '\n\n' + this.personalDataForm.controls.lastname.value + ' ' + this.personalDataForm.controls.firstname.value;

      this.billingFirstname = 'Cégnév: ' + this.personalDataForm.controls.companyName.value + ' ' +
        'Adószám: ' + this.personalDataForm.controls.companyVAT.value;

      this.billingLastname = this.personalDataForm.controls.lastname.value + ' ' + this.personalDataForm.controls.firstname.value;
    } else {
      this.costumerFirstName = this.personalDataForm.controls.firstname.value;
      this.costumerLastName = this.personalDataForm.controls.lastname.value;
      this.billingFirstname = this.personalDataForm.controls.firstname.value;
      this.billingLastname = this.personalDataForm.controls.lastname.value;
    }

    if (this.differentBillingAddressIsClicked === true) {
      this.costumerAddress = billingAddress_1;
      // tslint:disable-next-line:max-line-length
      this.service.setBilling(this.billingFirstname, this.billingLastname, email, billingAddress_1, billingAddress_2, billingCity, country_id).subscribe(response => {
        if (response.status === 0 && this.getErrorsLength() === 0) {
          this.service.setShippingAddress(firstname, lastname, email, address_1, address_2, city, country_id).subscribe(shipResponse => {
            this.redirect();
          }, error => {
            this.guestErrorWhileCheckingOut(this.persistance.get('logged_in_user'));
          });
        } else {
          this.openAlert(response);
        }
      }, error => {
        this.guestErrorWhileCheckingOut(this.persistance.get('logged_in_user'));
      });
    } else {
      this.service.setBilling(firstname, lastname, email, address_1, address_2, city, country_id).subscribe(response => {
        if (response.status === 0 && this.getErrorsLength() === 0) {
          this.service.setShippingAddress(firstname, lastname, email, address_1, address_2, city, country_id).subscribe(shipResponse => {
            this.redirect();
          }, error => {
            this.guestErrorWhileCheckingOut(this.persistance.get('logged_in_user'));
          });
        } else {
          this.errorMessages = response.error;
          this.openAlert(response);
          this.guestErrorWhileCheckingOut(this.persistance.get('logged_in_user'));
        }
      });
    }*/
  }

  openAlert(res): void {

    this.getErrors(res);

    if (this.errors.length !== 0) {
      const anjaAlert = Swal.mixin({
        customClass: {
          title: 'anja-alert-title',
          confirmButton: 'anja-alert-button',
          cancelButton: 'anja-cancel-button',
        }
      });

      anjaAlert.fire({
        icon: 'warning',
        /*title: this.translate.instant('BASKET.EMPTY_CART_WARNING'),*/
        text: this.translate.instant('ERRORS.MISSING') + this.errors,
        toast: false,
        timerProgressBar: true,
        position: 'center',
        focusConfirm: false,
        showConfirmButton: true,
        showCancelButton: false,
        confirmButtonColor: '#3D3D3D',
        iconColor: '#E01E70',
        confirmButtonText: this.translate.instant('LOGIN.OKAY'),
      });
    }
  }

  redirect(): void {
    this.router.navigate(['payment/shipping-and-payment']);
  }

  /*SZÁLLÍTÁSI MÓDOK*/
  getShippingData(): void {
    this.service.getShippingMethods().subscribe(response => {
      this.hasShipping = response.has_shipping
      this.gls_default = response.data ? response.data.weight : null;
      this.gls_cash_delivery = response.data ? response.data.gls_cod : null;
      this.gls_point = response.data && response.data.gls ? response.data.gls : null
      this.gls_point_cod = response.data && response.data.gls_point_cod ? response.data.gls_point_cod : null
      this.gls_preferred_time_window = response.data && response.data.gls_preferred_time_window ? response.data.gls_preferred_time_window : null
      this.gls_preferred_time_window_cod = response.data && response.data.gls_preferred_time_window_cod ? response.data.gls_preferred_time_window_cod : null
      if (this.gls_cash_delivery) {
        this.gls_cash_delivery.name = 'Utánvét';
      }

      this.service.getPaymentMethods().subscribe(paymentResponse => {
        console.log(paymentResponse)
        this.simple_pay = paymentResponse.payment_methods.simple_otp_payment;
        this.cod_payment = paymentResponse.payment_methods.cod;
      }, error => {
        this.guestErrorWhileCheckingOut(this.persistance.get('logged_in_user'));
      });
    }, error => {
      this.guestErrorWhileCheckingOut(this.persistance.get('logged_in_user'));
    });
  }

  /*toggleShippingButton(): void {
    this.isShipping1Clicked = !this.isShipping1Clicked;
    this.checkShippingButton();
  }*/

  /*checkShippingButton(): void {
    if (this.isShipping1Clicked === true) {
      this.shippingForm.controls.shipping1.setValue({shipping1: true});
      // @ts-ignore
      document.getElementById('shipping1').checked = true;
    } else if (this.isShipping1Clicked === false) {
      this.shippingForm.controls.shipping1.setValue({shipping1: false});
      // @ts-ignore
      document.getElementById('shipping1').checked = false;
    }
  }*/

  toggleSimplePay(): void {
    this.isSimplePayClicked = !this.isSimplePayClicked;
    if (this.isSimplePayClicked) {
      this.isCodClicked = false;
      this.checkCOD();
    }
    this.checkSimplePay();
  }

  toggleSimplePolicyButton(): void {
    this.isSimplePolicyChecked = !this.isSimplePolicyChecked;
    this.checkSimplePayPolicy();
  }

  checkSimplePayPolicy(): void {
    if (this.isSimplePolicyChecked === true) {
      // @ts-ignore
      document.getElementById('simplepay-policy').checked = true;
    } else if (this.isSimplePayClicked === false) {
      // @ts-ignore
      document.getElementById('simplepay-policy').checked = false;
    }
  }

  checkSimplePay(): void {
    if (this.isSimplePayClicked === true) {
      this.shippingForm.controls.simplepay.setValue({ simplepay: true });
      // @ts-ignore
      document.getElementById('simplepay').checked = true;
    } else if (this.isSimplePayClicked === false) {
      this.shippingForm.controls.simplepay.setValue({ simplepay: false });
      // @ts-ignore
      document.getElementById('simplepay').checked = false;
    }
  }

  toggleCOD(): void {
    this.isCodClicked = !this.isCodClicked;
    if (this.isCodClicked) {
      this.isSimplePayClicked = false;
      this.checkSimplePay();
    }
    this.checkCOD();
  }

  checkCOD(): void {
    if (this.isCodClicked === true) {
      this.shippingForm.controls.cod.setValue({ cod: true });
      if (document.getElementById('cod')) {
        // @ts-ignore
        document.getElementById('cod').checked = true;
      }
    } else if (this.isCodClicked === false) {
      this.shippingForm.controls.cod.setValue({ cod: false });
      if (document.getElementById('cod')) {
        // @ts-ignore
        document.getElementById('cod').checked = false;
      }
    }
  }

  checkSelectedShippingMethod(): void {
    if (!this.hasShipping) {
      this.selectedShippingMethod = ''
      return;
    }

    const selectedShippingMethod = this.shippingForm.get('shipping').value

    if (selectedShippingMethod == 'gls' && this.isSimplePayClicked === true) {
      this.selectedShippingMethod = this.gls_default.quote.weight_5.code;
      this.costumerShippingMethod = this.gls_default.title;
    } else if (selectedShippingMethod == 'gls' && this.isCodClicked === true) {
      this.selectedShippingMethod = this.gls_cash_delivery.quote.gls_cod5.code;
      this.costumerShippingMethod = this.gls_cash_delivery.title;
    } else if (selectedShippingMethod == 'gls_point' && this.isSimplePayClicked === true) {
      this.selectedShippingMethod = this.gls_point.quote.gls5.code;
      this.costumerShippingMethod = this.gls_point.title;
    } else if (selectedShippingMethod == 'gls_point' && this.isCodClicked === true) {
      this.selectedShippingMethod = this.gls_point_cod.quote.gls_point_cod5.code;
      this.costumerShippingMethod = this.gls_point_cod.title;
    } else if (selectedShippingMethod == 'gls_preferred_time_window' && this.isCodClicked === false) {
      this.selectedShippingMethod = this.gls_preferred_time_window.quote.gls_preferred_time_window5.code;
      this.costumerShippingMethod = this.gls_preferred_time_window.title + ' (' + 'Preferált szállítási időablak: ' + this.shippingForm.controls.selectedTimeWindow.value + ')';
    } 
    else if (selectedShippingMethod == 'gls_preferred_time_window' && this.isCodClicked === true) {
      this.selectedShippingMethod = this.gls_preferred_time_window_cod.quote.gls_preferred_time_window_cod5.code;
      this.costumerShippingMethod = this.gls_preferred_time_window_cod.title + ' (' + 'Preferált szállítási időablak: ' + this.shippingForm.controls.selectedTimeWindow.value + ')';
    } 
    else {
      this.selectedShippingMethod = '';
    }
  }

  getSelectedShippingMethod(): string {
    if (!this.hasShipping) {
      return ''
    }

    const selectedShippingMethod = this.shippingForm.get('shipping').value

    if (selectedShippingMethod == 'gls' && this.isSimplePayClicked === true) {
      this.selectedShippingMethod = this.gls_default.quote.weight_5.code;
      this.costumerShippingMethod = this.gls_default.title;
    } else if (selectedShippingMethod == 'gls' && this.isCodClicked === true) {
      this.selectedShippingMethod = this.gls_cash_delivery.quote.gls_cod5.code;
      this.costumerShippingMethod = this.gls_cash_delivery.title;
    } else if (selectedShippingMethod == 'gls_point' && this.isSimplePayClicked === true) {
      this.selectedShippingMethod = this.gls_point.quote.gls5.code;
      this.costumerShippingMethod = this.gls_point.title;
    } else if (selectedShippingMethod == 'gls_point' && this.isCodClicked === true) {
      this.selectedShippingMethod = this.gls_point_cod.quote.gls_point_cod5.code;
      this.costumerShippingMethod = this.gls_point_cod.title;
    }

    return '';
  }


  checkSelectedPaymentMethod(): void {
    if (this.isSimplePayClicked === true) {
      this.selectedPaymentMethod = this.simple_pay.code;
      this.costumerPaymentMethod = this.simple_pay.title;
    } else if (this.isCodClicked === true) {
      this.selectedPaymentMethod = this.cod_payment.code;
      this.costumerPaymentMethod = this.gls_cash_delivery.title;
    } else {
      this.selectedPaymentMethod = '';
    }
  }

  setShippingAndPayment(): void {
    /* ha a szállítás módja GLS csomagpont, akkor ki kell választani egy csomagpontot */
    if (this.shippingForm.get('shipping').value == 'gls_point' && !this.gls_point_code) {
      const anjaAlert = Swal.mixin({
        customClass: {
          title: 'anja-alert-title',
          confirmButton: 'anja-alert-button',
          cancelButton: 'anja-cancel-button',
        }
      });
      anjaAlert.fire({
        icon: 'warning',
        text: 'Válasszon GLS csomagpontot!',
        toast: false,
        timerProgressBar: true,
        position: 'center',
        focusConfirm: false,
        showConfirmButton: true,
        showCancelButton: false,
        confirmButtonColor: '#3D3D3D',
        iconColor: '#E01E70',
        confirmButtonText: this.translate.instant('LOGIN.OKAY'),
      });

      return;
    }

    /* ha a szállítási módja GLS Házhozszállítás - Preferált szállítási időablakkal */
    if (this.shippingForm.get('shipping').value == 'gls_preferred_time_window' && !this.shippingForm.controls.selectedTimeWindow.value) {
      const anjaAlert = Swal.mixin({
        customClass: {
          title: 'anja-alert-title',
          confirmButton: 'anja-alert-button',
          cancelButton: 'anja-cancel-button',
        }
      });
      anjaAlert.fire({
        icon: 'warning',
        text: 'Válasszon \'Preferált Szállítási Időablakot!\'',
        toast: false,
        timerProgressBar: true,
        position: 'center',
        focusConfirm: false,
        showConfirmButton: true,
        showCancelButton: false,
        confirmButtonColor: '#3D3D3D',
        iconColor: '#E01E70',
        confirmButtonText: this.translate.instant('LOGIN.OKAY'),
      });

      return;
    }

    /* fizetési mód nem lett választva */
    if (this.isSimplePayClicked === false && this.isCodClicked === false) {
      const anjaAlert = Swal.mixin({
        customClass: {
          title: 'anja-alert-title',
          confirmButton: 'anja-alert-button',
          cancelButton: 'anja-cancel-button',
        }
      });
      anjaAlert.fire({
        icon: 'warning',
        text: 'Válassz fizetési módot!',
        toast: false,
        timerProgressBar: true,
        position: 'center',
        focusConfirm: false,
        showConfirmButton: true,
        showCancelButton: false,
        confirmButtonColor: '#3D3D3D',
        iconColor: '#E01E70',
        confirmButtonText: this.translate.instant('LOGIN.OKAY'),
      });

      return;
    }

    this.checkSelectedShippingMethod();
    this.checkSelectedPaymentMethod();

    this._setShippingData();

    this._setComment();

    this.router.navigate(['payment/summary']);
  }

  private _setComment() {
      if (this.shippingForm.get('shipping').value == 'gls_preferred_time_window' ) {
        this.costumerComment = 'Preferált szállítási időablak: ' + this.shippingForm.controls.selectedTimeWindow.value
      }
    }

  async pay() {

    this.isSubmitted = true;
    setInterval(() => {
      if (this.dot) {
        this.dot = false;
      } else {
        this.dot = true;
      }
    }, 1000);

    /* SETTING BILLING DATA */
    const billingRes = await this.service.setBilling(this.billingData.firstName, this.billingData.lastName, this.billingData.email, this.billingData.address1, this.billingData.address2, this.billingData.city, this.billingData.countryId);

    /* if error happened */
    if (billingRes.status != 0 || billingRes.error || this.getErrorsLength() > 0) {
      this.isSubmitted = false;
      this.openAlert(billingRes);
      return
    }

    /* SETTING SHIPPING DATA */
    const shippingRes = await this.service.setShippingAddress(this.shippingData.firstName, this.shippingData.lastName, this.shippingData.email, this.shippingData.address1, this.shippingData.address2, this.shippingData.city, this.shippingData.countryId);

    /* if error happened */
    if (shippingRes.status != 0 || shippingRes.error || this.getErrorsLength() > 0) {
      this.isSubmitted = false;
      this.openAlert(shippingRes);
      return
    }

    /* GET SHIPPING METHODS */
    const getShippingMethodsRes = await this.service.getShippingMethodsAsync()

    /* if error happened */
    if (getShippingMethodsRes.status != 0 || getShippingMethodsRes.error || this.getErrorsLength() > 0) {
      this.isSubmitted = false;
      this.openAlert(getShippingMethodsRes);
      return
    }

    this.hasShipping = getShippingMethodsRes.has_shipping
    this.gls_default = getShippingMethodsRes.data ? getShippingMethodsRes.data.weight : null;
    this.gls_cash_delivery = getShippingMethodsRes.data ? getShippingMethodsRes.data.gls_cod : null;
    this.gls_point = getShippingMethodsRes.data && getShippingMethodsRes.data.gls ? getShippingMethodsRes.data.gls : null
    this.gls_point_cod = getShippingMethodsRes.data && getShippingMethodsRes.data.gls_point_cod ? getShippingMethodsRes.data.gls_point_cod : null
    if (this.gls_cash_delivery) {
      this.gls_cash_delivery.name = 'Utánvét';
    }

    /* GET PAYMENT METHODS */
    const getPaymentMethodsRes = await this.service.getPaymentMethodsAsync();

    /* if error happened */
    if (getPaymentMethodsRes.status != 0 || getPaymentMethodsRes.error  || this.getErrorsLength() > 0) {
      this.isSubmitted = false;
      this.openAlert(getPaymentMethodsRes);
      return
    }

    this.simple_pay = getPaymentMethodsRes.payment_methods.simple_otp_payment;
    this.cod_payment = getPaymentMethodsRes.payment_methods.cod;

    /* SETTING PAYMENT METHOD */
    const paymentMethodRes = await this.service.setPaymentMethod(this.selectedPaymentMethod);

    /* if error happened */
    if (paymentMethodRes.status != 0 || paymentMethodRes.error  || this.getErrorsLength() > 0) {
      this.isSubmitted = false;
      this.openAlert(paymentMethodRes);
      return
    }

    /* SETTING SHIPPING METHOD */
    const shippingMethodRes = await this.service.setShippingMethod(this.selectedShippingMethod);

    /* if error happened */
    if (shippingMethodRes.status != 0 || shippingMethodRes.error || this.getErrorsLength() > 0) {
      this.isSubmitted = false;
      this.openAlert(shippingMethodRes);
      return
    }

    /* SET CUSTOMER */
    const customerMethodRes = await this.service.setCustomer(this.shippingData.firstName, this.shippingData.lastName, this.shippingData.email, this.shippingData.phone)

    /* if error happened */
    if (customerMethodRes.status != 0 || customerMethodRes.error || this.getErrorsLength() > 0) {
      this.isSubmitted = false;
      this.openAlert(customerMethodRes);
      return
    }

    /* place order request */
    const placeOrderRes = await this.service.sendOrder(this.costumerComment)
    
    /* if error happened */
    if (placeOrderRes.status != 0 || placeOrderRes.error || this.getErrorsLength() > 0) {
      this.isSubmitted = false;
      this.openAlert(placeOrderRes);
      return
    }
    /* PLACE ORDER */
    /* if payment method is SIMPLE */
    if (this.selectedPaymentMethod === 'simple_otp_payment') {
      // Successful simple request
      if (placeOrderRes.data.payment.otpData.paymentUrl) {
        this.simplePayURL = placeOrderRes.data.payment.otpData.paymentUrl;
        document.location.href = this.simplePayURL;
        return false;
      }
      /* error happened */
      this.router.navigate(['/simplepay/error/' + placeOrderRes.data.order_id], { queryParams: { codes: Object.values(JSON.parse(placeOrderRes.data.payment.otpData.responseBody).errorCodes).join('-') } });
      return
    }
    /* if payment method is CASH ON DELIVERY */
    this.router.navigate(['/payment/success/' + placeOrderRes.data.order_id]);

    return;

    /* FIRST NAME */
    const firstname = this.personalDataForm.controls.firstname.value;
    /* LAST NAME */
    const lastname = this.personalDataForm.controls.lastname.value;
    /* NAME */
    const name = this.personalDataForm.controls.firstname.value + ' ' + this.personalDataForm.controls.lastname.value;
    /* CUSTOMER NAME */
    this.costumerName = name;
    /* CUSTOMER EMAIL */
    this.costumerEmail = this.personalDataForm.controls.email.value;
    // tslint:disable-next-line:max-line-length
    /* CUSTOMER ADDRESS */
    this.costumerAddress = this.personalDataForm.controls.irszam.value + ' ' + this.personalDataForm.controls.city.value + ' ' + this.personalDataForm.controls.cim.value +
      ' ' + this.personalDataForm.controls.hazszam.value;
    /* CUSTOMER PHONE */
    this.costumerPhone = this.personalDataForm.controls.phone.value;
    /* CUSTOMER OTHER */
    this.costumerOther = this.personalDataForm.controls.address_2.value;
    /* EMAIL */
    const email = this.costumerEmail;
    /* CITY */
    const city = !this.isGlsPointShipping ? this.personalDataForm.controls.city.value : this.gls_point_details.city;
    // tslint:disable-next-line:max-line-length
    /* ADDRESS 1 */
    const address_1 = !this.isGlsPointShipping ? this.personalDataForm.controls.irszam.value + ' ' + this.personalDataForm.controls.city.value + ' ' + this.personalDataForm.controls.cim.value +
      ' ' + this.personalDataForm.controls.hazszam.value : this.gls_point_details.zipcode + ' ' + this.gls_point_details.city + ', ' + this.gls_point_details.address;
    /* ADDRESS 2 */
    const address_2 = !this.isGlsPointShipping ? this.personalDataForm.controls.address_2.value : this.gls_point_details.name;
    /* BILLING CITY */
    const billing_City = this.personalDataForm.controls.billingCity.value;
    /* BILLING ADDRESS 1 */
    const billingAddress_1 = this.personalDataForm.controls.billingIrszam.value + ' ' + this.personalDataForm.controls.billingCity.value + ' ' + this.personalDataForm.controls.billingCim.value +
      ' ' + this.personalDataForm.controls.billingHazszam.value + ' '
      + this.personalDataForm.controls.billingAddress_2.value;
    /* BILLING ADDRESS 2 */
    const billingAddress_2 = this.personalDataForm.controls.billingAddress_2.value;

    /* COUNTRY ID = 97 */
    const country_id = 97;

    /* SUBSCRIBE USER TO NEWSLETTER */
    if (this.personalDataForm.controls.isSubbed.value === true || this.isSubClicked === true) {
      this.service.subscribeMail(email, 1, 1, name).subscribe(response => { }, error => { });
    }

    /******
     * SET BILLING ADDRESS 
    ******/
    console.log(this.personalDataForm)

    /* CUSTOMER NAME */
    const billingCustomerName = this.companyBillingIsClicked ? 'Cégnév: \n' + this.personalDataForm.controls.companyName.value + ' ' +
      // tslint:disable-next-line:max-line-length
      '\n\nAdószám: \n' + this.personalDataForm.controls.companyVAT.value + '\n\n' + this.personalDataForm.controls.lastname.value + ' ' + this.personalDataForm.controls.firstname.value : null;
    /* BILLING FIRST NAME */
    const billingFirstName = this.companyBillingIsClicked ? 'Cégnév: ' + this.personalDataForm.controls.companyName.value + ' ' +
      'Adószám: ' + this.personalDataForm.controls.companyVAT.value : this.personalDataForm.controls.firstname.value;
    /* BILLING LAST NAME */
    const billingLastName = this.companyBillingIsClicked ? this.personalDataForm.controls.lastname.value + ' ' + this.personalDataForm.controls.firstname.value : this.personalDataForm.controls.lastname.value;
    /* CUSTOMER ADDRESS */
    this.costumerAddress = this.companyBillingIsClicked ? billingAddress_1 : null;
    const billingAddress1 = this.companyBillingIsClicked ? billingAddress_1 : this.personalDataForm.controls.address_1.value
    const billingAddress2 = this.companyBillingIsClicked ? billingAddress_2 : this.personalDataForm.controls.address_2.value
    const billingCity = this.companyBillingIsClicked ? billing_City : this.personalDataForm.controls.city.value

    //const res = await this.service.setBilling(billingFirstName, billingLastName, email, billingAddress1, billingAddress2, billingCity, country_id)
    //console.log(res)

    return;



  }

  /*this.service.setPaymentMethod(this.selectedPaymentMethod).subscribe(payResponse => {

    this.service.setShippingMethod(this.selectedShippingMethod).subscribe(shipResponse => {

      this.service.setCustomer(this.costumerFirstName, this.costumerLastName, this.costumerEmail, this.costumerPhone).subscribe(response => {

        if (response.error) {
          this.anjaAlert.fire({
            icon: 'warning',
            text: Object.values(response.error).join(' '),
            toast: false,
            timerProgressBar: true,
            position: 'center',
            focusConfirm: false,
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonColor: '#3D3D3D',
            iconColor: '#E01E70',
            confirmButtonText: this.translate.instant('LOGIN.OKAY'),
          });
          this.isSubmitted = false;
          return false;
        }

        this.service.sendOrder(this.costumerComment).subscribe(res => {
          if(res.error) {
            this.isSubmitted = false;
            const anjaAlert = Swal.mixin({
              customClass: {
                title: 'anja-alert-title',
                confirmButton: 'anja-alert-button',
                cancelButton: 'anja-cancel-button',
              }
            });
            anjaAlert.fire(
              'Sajnáljuk',
               res.error,
              'warning'
            );
            return ;
          }

          setTimeout(() => {
            this.isSubmitted = false;
          }, 1000);

          if (this.selectedPaymentMethod === 'simple_otp_payment') {
            // this.simplePayURL = res.data.payment.otpData.paymentUrl;
            // Successful simple request
            if (res.data.payment.otpData.paymentUrl) {
              this.simplePayURL = res.data.payment.otpData.paymentUrl;
              document.location.href = this.simplePayURL;
              //console.log('navigated');
              return false;
            }
            //console.log(res.data.payment.otpData.responseBody);

            this.router.navigate(
              ['/products'],
              { queryParams: { order: 'popular' } }
            );

            // tslint:disable-next-line:max-line-length
            this.router.navigate(['/simplepay/error/' + res.data.order_id],
              {queryParams: {codes: Object.values(JSON.parse(res.data.payment.otpData.responseBody).errorCodes).join('-')}});
          } else {
            this.router.navigate(['/payment/success/' + res.data.order_id]);
          }
        }, error => {
          //console.log(error);
          this.guestErrorWhileCheckingOut(this.persistance.get('logged_in_user'));
        });
      }, error => {
        setTimeout(() => {
          this.isSubmitted = false;
        }, 1000);
        this.guestErrorWhileCheckingOut(this.persistance.get('logged_in_user'));
        // this.service.refreshToken(this.persistance.get('logged_in_user'));
      });
    }, error => {
      setTimeout(() => {
        this.isSubmitted = false;
      }, 1000);
      this.guestErrorWhileCheckingOut(this.persistance.get('logged_in_user'));
      // this.service.refreshToken(this.persistance.get('logged_in_user'));
    });
  }, error => {
    setTimeout(() => {
      this.isSubmitted = false;
    }, 1000);
    this.guestErrorWhileCheckingOut(this.persistance.get('logged_in_user'));
    // this.service.refreshToken(this.persistance.get('logged_in_user'));
  });
}*/

  guestErrorWhileCheckingOut(loggedInUser): void {
    this.service.getCart().subscribe(resposne => {
    }, error => {
      if (!loggedInUser) {
        const anjaAlert = Swal.mixin({
          customClass: {
            title: 'anja-alert-title',
            confirmButton: 'anja-alert-button',
            cancelButton: 'anja-cancel-button',
          }
        });
        anjaAlert.fire(
          'Sajnáljuk',
          'Túl sokáig nem volt aktivitás az oldalon, kezdje újra vásárlást!',
          'warning'
        );
      }
    });
  }

  getErrors(res): void {
    this.errors = [];

    if (res.status !== 0) {
      let errorTextFound = false
      if (res.error.firstname) {
        errorTextFound = true
        this.errors.push(this.translate.instant('ERRORS.FIRSTNAME'));
      }

      if (res.error.lastname) {
        errorTextFound = true
        this.errors.push(this.translate.instant('ERRORS.LASTNAME'));
      }

      if (!this.personalDataForm.controls.city.value) {
        errorTextFound = true
        this.errors.push(this.translate.instant('ERRORS.CITY'));
      }
      if (!errorTextFound) {
        this.errors.push(Object.values(res.error).join(' '))
      }
    }

    if (!this.shippingData.email) {
      this.errors.push(this.translate.instant('ERRORS.EMAIL'));
    }

    if (!this.shippingData.phone) {
      this.errors.push(this.translate.instant('ERRORS.PHONE'));
    }

    if (!this.personalDataForm.controls.irszam.value) {
      this.errors.push(this.translate.instant('ERRORS.ZIP'));
    }

    if (!this.personalDataForm.controls.cim.value) {
      this.errors.push(this.translate.instant('ERRORS.ADDRESS'));
    }

    if (!this.personalDataForm.controls.hazszam.value) {
      this.errors.push(this.translate.instant('ERRORS.ADDRESS_NUMBER'));
    }

    if (this.differentBillingAddressIsClicked) {
      if (!this.personalDataForm.controls.billingIrszam.value) {
        this.errors.push(this.translate.instant('ERRORS.BILL_ZIP'));
      }

      if (!this.personalDataForm.controls.billingCity.value) {
        this.errors.push(this.translate.instant('ERRORS.BILL_CITY'));
      }

      if (!this.personalDataForm.controls.billingCim.value) {
        this.errors.push(this.translate.instant('ERRORS.BILL_ADDRESS'));
      }

      if (!this.personalDataForm.controls.billingHazszam.value) {
        this.errors.push(this.translate.instant('ERRORS.BILL_ADDRESS_NUMBER'));
      }
    }

    if (this.companyBillingIsClicked) {
      if (!this.personalDataForm.controls.companyName.value) {
        this.errors.push(this.translate.instant('ERRORS.FIRM_NAME'));
      }

      if (!this.personalDataForm.controls.companyVAT.value) {
        this.errors.push(this.translate.instant('ERRORS.TAX_NUMBER'));
      }
    }

    /*if (!this.personalDataForm.controls.policyChecked.value || !this.policyIsClicked) {
      this.errors.push(this.translate.instant('ERRORS.POLICY'));
    }

    if (!this.personalDataForm.controls.termsChecked.value || !this.termsIsClicked) {
      this.errors.push(this.translate.instant('ERRORS.TERMS'));
    }*/
    console.log(this.policyIsClicked)
    if (!this.policyIsClicked) {
      this.errors.push(this.translate.instant('ERRORS.POLICY'));
    }

    console.log(this.termsIsClicked)
    if (!this.termsIsClicked) {
      this.errors.push(this.translate.instant('ERRORS.TERMS'));
    }
  }

  getErrorsLength(): number {
    this.errors = [];

    if (!this.shippingData.email) {
      this.errors.push(this.translate.instant('ERRORS.EMAIL'));
    }

    if (!this.shippingData.phone) {
      this.errors.push(this.translate.instant('ERRORS.PHONE'));
    }

    if (!this.personalDataForm.controls.irszam.value) {
      this.errors.push(this.translate.instant('ERRORS.ZIP'));
    }

    if (!this.personalDataForm.controls.cim.value) {
      this.errors.push(this.translate.instant('ERRORS.ADDRESS'));
    }

    if (!this.personalDataForm.controls.hazszam.value) {
      this.errors.push(this.translate.instant('ERRORS.ADDRESS_NUMBER'));
    }

    if (this.differentBillingAddressIsClicked) {
      if (!this.personalDataForm.controls.billingIrszam.value) {
        this.errors.push(this.translate.instant('ERRORS.BILL_ZIP'));
      }

      if (!this.personalDataForm.controls.billingCity.value) {
        this.errors.push(this.translate.instant('ERRORS.BILL_CITY'));
      }

      if (!this.personalDataForm.controls.billingCim.value) {
        this.errors.push(this.translate.instant('ERRORS.BILL_ADDRESS'));
      }

      if (!this.personalDataForm.controls.billingHazszam.value) {
        this.errors.push(this.translate.instant('ERRORS.BILL_ADDRESS_NUMBER'));
      }
    }

    if (this.companyBillingIsClicked) {
      if (!this.personalDataForm.controls.companyName.value) {
        this.errors.push(this.translate.instant('ERRORS.FIRM_NAME'));
      }

      if (!this.personalDataForm.controls.companyVAT.value) {
        this.errors.push(this.translate.instant('ERRORS.TAX_NUMBER'));
      }
    }

    console.log(this.policyIsClicked)
    if (!this.policyIsClicked) {
      this.errors.push(this.translate.instant('ERRORS.POLICY'));
    }

    console.log(this.termsIsClicked)
    if (!this.termsIsClicked) {
      this.errors.push(this.translate.instant('ERRORS.TERMS'));
    }
    return this.errors.length;
  }

  showCod(cod): string {
    if (cod === 'GLS Futárszolgálat Utánvét') {
      return 'Utánvét';
    } else {
      return cod;
    }
  }

  get shouldShowGlsPointMap() {
    return this.shippingForm.get('shipping').value == 'gls_point' && this.showGlsPointMap
  }

  get shouldShowGlsPreferredTimeWindowTextArea() {
    return this.shippingForm.get('shipping').value == 'gls_preferred_time_window'
  }

  checkPaymentMethod(): boolean {
    return this.costumerPaymentMethod === 'Bankkártya (Mastercard, Maestro, Visa, Visa Electron, American Express)';
  }

  prettierPaymentType(payment, section): string {
    return payment.split(' ')[section];
  }

  popUpWarning(): void {
    const anjaAlert = Swal.mixin({
      customClass: {
        title: 'anja-alert-title',
        confirmButton: 'anja-alert-button',
        cancelButton: 'anja-cancel-button',
      }
    });
    anjaAlert.fire({
      icon: 'warning',
      text: this.translate.instant('PAYMENT.MISSING_SIMPLE'),
      toast: false,
      timerProgressBar: true,
      position: 'center',
      focusConfirm: false,
      showConfirmButton: true,
      showCancelButton: false,
      confirmButtonColor: '#3D3D3D',
      iconColor: '#E01E70',
      confirmButtonText: this.translate.instant('LOGIN.OKAY'),
    });
  }

  applyCoupon(): void {
    const anjaAlert = Swal.mixin({
      customClass: {
        title: 'anja-alert-title',
        confirmButton: 'anja-alert-button',
        cancelButton: 'anja-cancel-button',
      }
    });

    this.service.applyCoupon(this.couponCode).subscribe(response => {
      if (response.data.error) {
        anjaAlert.fire({
          icon: 'warning',
          text: response.data.error,
          toast: false,
          timerProgressBar: true,
          position: 'center',
          focusConfirm: false,
          showConfirmButton: true,
          showCancelButton: false,
          confirmButtonColor: '#3D3D3D',
          iconColor: '#E01E70',
          confirmButtonText: this.translate.instant('LOGIN.OKAY'),
        });
      } else if (response.data.msg) {
        this.checkForCoupon();

        anjaAlert.fire({
          icon: 'success',
          text: response.data.msg,
          toast: false,
          timerProgressBar: true,
          position: 'center',
          focusConfirm: false,
          showConfirmButton: true,
          showCancelButton: false,
          confirmButtonColor: '#3D3D3D',
          iconColor: '#E01E70',
          confirmButtonText: this.translate.instant('LOGIN.OKAY'),
        });
      }
    }, error => {
      anjaAlert.fire({
        icon: 'warning',
        text: error.error.data.error,
        toast: false,
        timerProgressBar: true,
        position: 'center',
        focusConfirm: false,
        showConfirmButton: true,
        showCancelButton: false,
        confirmButtonColor: '#3D3D3D',
        iconColor: '#E01E70',
        confirmButtonText: this.translate.instant('LOGIN.OKAY'),
      });
    });
  }

  getTotalPrice(totals, totalPrice): string {
    const found = totals.find(total => total.title === totalPrice);
    if (found) {
      return found.text;
    } else {
      return '';
    }
  }

  checkCoupon(data): string {
    if (data && data.coupon && data.coupon.coupon) {
      return data.coupon.coupon;
    }
  }

  removeCoupon(): void {
    const anjaAlert = Swal.mixin({
      customClass: {
        title: 'anja-alert-title',
        confirmButton: 'anja-alert-button',
        cancelButton: 'anja-cancel-button',
      }
    });

    this.service.deleteCoupon().subscribe(response => {
      this.coupon = '';
      this.couponOff = '';

      anjaAlert.fire({
        icon: 'success',
        text: response.data.msg,
        toast: false,
        timerProgressBar: true,
        position: 'center',
        focusConfirm: false,
        showConfirmButton: true,
        showCancelButton: false,
        confirmButtonColor: '#3D3D3D',
        iconColor: '#E01E70',
        confirmButtonText: this.translate.instant('LOGIN.OKAY'),
      });
    });
  }

  checkForCoupon(): void {
    this.service.getCart().subscribe(response => {
      this.checkCoupon(response);
      this.hasShipping = response.data.hasShipping
      this.coupon = this.checkCoupon(response.data);
      this.couponOff = this.getTotalPrice(response.data.totals, this.coupon);
    });
  }

  scrollTop(): void {
    window.scrollTo({
      top: 0,
      left: 0,
      // @ts-ignore
      behavior: 'instant',
    });
  }

  get glsPointPrice() {
    if (!this.gls_point || !this.gls_point.quote) return ""

    const quotes = this.gls_point.quote

    return quotes[Object.keys(quotes)[0]].text
  }

  get isGlsPointShipping() {
    return this.shippingForm.get('shipping').value == 'gls_point'
  }

  get isGlsPreferredTimeWindow() {
    return this.shippingForm.get('shipping').value == 'gls_preferred_time_window'
  }

  showGlsPointMapFn() {
    this.showGlsPointMap = true
  }

  glsPointSelected(object: any) {
    console.log(object)
    this.gls_point_code = object.pclshopid
    this.gls_point_title = object.city.trim() + ', ' + object.name.trim() + ' (' + object.address.trim() + ')'
    this.gls_point_details = {
      country: object.ctrcode.trim(),
      zipcode: object.zipcode.trim(),
      city: object.city.trim(),
      address: object.address.trim(),
      name: object.name.trim(),
      code: object.pclshopid.trim(),
    }
  }

}

