<section class="blog-section container">
    <section class="line"></section>
    <p class="title-large">{{'MAIN.LATEST_BLOGS' | translate}}</p>
    <p id="blog-intro-text" class="paragraph-text">{{'MAIN.ABOUT_BLOGS' | translate}}</p>

    <section class="blogs-container container">
        <section class="row justify-content-center">
            <section class="col-12 col-md-6 col-lg-3">
                <source srcset="{{blogs[0] && blogs[0].images.webp}}" type="image/webp">
                <source srcset="{{blogs[0] && blogs[0].images.original}}" type="image/webp">
                <img class="blog-pic a-blog-pic" routerLink="/blog/{{blogs[0] && blogs[0]['seo_url']}}" src="{{blogs[0] &&  blogs[0].images.original}}" alt="Alt Text!">

                <p class="blog-title" routerLink="/blog/{{blogs[0] && blogs[0]['seo_url']}}">{{blogs[0] && blogs[0].title}}</p>
                <div class="blog-lead" routerLink="/blog/{{blogs[0] && blogs[0]['seo_url']}}">
                  <ngx-dynamic-hooks [content]="blogs[0] && blogs[0].lead "></ngx-dynamic-hooks>
                </div>

                <button class="button under-blog-button" routerLink="/blog/{{blogs[0] && blogs[0]['seo_url']}}">{{"BUTTON.READ_MORE" | translate}}</button>
            </section>

            <section class="col-12 col-md-6 col-lg-3">
                <source srcset="{{blogs[1] && blogs[1].images.webp}}" type="image/webp">
                <source srcset="{{blogs[1] && blogs[1].images.original}}" type="image/webp">
                <img class="blog-pic b-blog-pic" routerLink="/blog/{{blogs[1] && blogs[1]['seo_url']}}" src="{{blogs[1] && blogs[1].images.original}}" alt="Alt Text!">

                <p class="blog-title" routerLink="/blog/{{blogs[1] && blogs[1]['seo_url']}}">{{blogs[1] && blogs[1].title}}</p>
                <div class="blog-lead" routerLink="/blog/{{blogs[1] && blogs[1]['seo_url']}}">
                  <ngx-dynamic-hooks [content]="blogs[1] && blogs[1].lead "></ngx-dynamic-hooks>
                </div>

                <button class="button under-blog-button" routerLink="/blog/{{blogs[1] && blogs[1]['seo_url']}}">{{"BUTTON.READ_MORE" | translate}}</button>
            </section>

            <section class="col-12 col-md-6 col-lg-3">
                <source srcset="{{blogs[2] && blogs[2].images.webp}}" type="image/webp">
                <source srcset="{{blogs[2] && blogs[2].images.original}}" type="image/webp">
                <img class="blog-pic c-blog-pic" routerLink="/blog/{{blogs[2] && blogs[2]['seo_url']}}" src="{{blogs[2] && blogs[2].images.original}}" alt="Alt Text!">

                <p class="blog-title" routerLink="/blog/{{blogs[2] && blogs[2]['seo_url']}}">{{blogs[2] && blogs[2].title}}</p>
                <div class="blog-lead" routerLink="/blog/{{blogs[2] && blogs[2]['seo_url']}}">
                  <ngx-dynamic-hooks [content]="blogs[2] && blogs[2].lead "></ngx-dynamic-hooks>
                </div>

                <button class="button under-blog-button" routerLink="/blog/{{blogs[2] && blogs[2]['seo_url']}}">{{"BUTTON.READ_MORE" | translate}}</button>
            </section>

            <section class="col-12 col-md-6 col-lg-3">
                <source srcset="{{blogs[3] && blogs[3].images.webp}}" type="image/webp">
                <source srcset="{{blogs[3] && blogs[3].images.original}}" type="image/webp">
                <img class="blog-pic d-blog-pic" routerLink="/blog/{{blogs[3] && blogs[3]['seo_url']}}" src="{{blogs[3] && blogs[3].images.original}}" alt="Alt Text!">

                <p class="blog-title" routerLink="/blog/{{blogs[3] && blogs[3]['seo_url']}}">{{blogs[3] && blogs[3].title}}</p>
                <div class="blog-lead" routerLink="/blog/{{blogs[3] && blogs[3]['seo_url']}}">
                  <ngx-dynamic-hooks [content]="blogs[3] &&  blogs[3].lead"></ngx-dynamic-hooks>
                </div>

                <button class="button under-blog-button" routerLink="/blog/{{blogs[3] && blogs[2]['seo_url']}}">{{"BUTTON.READ_MORE" | translate}}</button>

            </section>
        </section>
    </section>
</section>
