import {Component, OnInit} from '@angular/core';
import {AppService} from '../app.service';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css']
})
export class BlogsComponent implements OnInit {

  blogs = [];

  constructor(private service: AppService) {
  }

  ngOnInit(): void {
    window.scrollTo({
      top: 0,
      left: 0,
      // @ts-ignore
      behavior: 'instant',
    });

    this.service.getBlogs().subscribe(blogs => {
      Object.values(blogs.data).forEach(blog => {
        this.blogs.push(blog);
      });
    });
  }

  setBlogClassByIndex(index): number {
    if (index > 3) {
      return index % 4;
    } else {
      return index;
    }
  }
}
