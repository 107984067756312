<section class="space-container">
<section class="about-us-container">
  <section class="row justify-content-center">

    <section class="first-container">
      <h1 class="title">{{'ABOUT_US.OUR_MISSION' | translate}}</h1>
      <p class="first-paragraph">{{'ABOUT_US.MISSION_1' | translate}}</p>
      <img *ngIf="lang === 'hu-hu'" class="stamp-image col-12" src="assets/images/szlogen.svg" alt="szlogen">
      <img *ngIf="lang === 'en-gb'" class="stamp-image col-12" src="assets/images/szlogen_en.svg" alt="motto">
    </section>
    <div class="candle-image-container col-12">
      <p class="picture-text">{{'ABOUT_US.PICTURE_TEXT' | translate}}</p>
      <img class="candle-image" src="assets/images/anja_candles.png">
    </div>
    <section class="second-container">
      <section class="content-wrapper row justify-content-md-center">
        <p class="second-paragraph col-12 col-md-9 col-lg-6">{{'ABOUT_US.MISSION_2' | translate}}</p>
        <img class="soap-image col-12 col-lg-6" src="assets/images/anja_soap.png">

        <div class="icon-wrapper">
          <img class="insta-icon" src="assets/images/instagram_icon_black.svg">
        </div>

        <div class="container">
          <div class="info-wrapper row justify-content-center">
            <p class="instagram-text col-12">{{'ABOUT_US.INSTAGRAM_TEXT' | translate}}</p>
            <button (click)="goToLink('https://www.instagram.com/anja_thebrand/')"
                    class="redirect-button col-12">{{'ABOUT_US.HEAD_TO_PROFILE' | translate}}</button>
            <p class="first-paragraph col-12">{{'ABOUT_US.WITH_LOVE' | translate}}</p>
            <img class="anja-signature-image col-12" src="assets/images/anja_signature.svg">
          </div>
        </div>
      </section>
    </section>

<!--    ToDo: Use translate module-->

    <section class="third-container">
      <p class="link-text">Ha kíváncsi vagy, milyen értékeket tartunk szem előtt a termékek fejlesztésénél,
        <span class="clickable-text" (click)="scrollToValues()">kattints ide</span>.
      </p>
    </section>
  </section>
</section>
</section>
