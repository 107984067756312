<section class="ultra-container container ">
  <section class="mega-container container">
    <section class="bg-wrapper container">
      <section class="row justify-content-center">
        <section class="contact-container row justify-content-center">
          <section class="container">
            <h1 class="contact-title">{{"CANCEL.TITLE" | translate}}</h1>

            <section *ngIf="orderId !== 0">
              <p class="contact-text">{{"CANCEL.TRANSACTION_ID" | translate}} {{orderId}}</p>
            </section>

            <p class="contact-text">{{"CANCEL.DESCRIPTION" | translate}}</p>

            <img src="../../assets/images/anja_sign.svg">
          </section>
        </section>
      </section>
    </section>
  </section>
</section>
