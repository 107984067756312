<section class="values-wrapper">
  <section class="values-container container">
    <section class="row">
      <!-- // Tudás-->

      <section *ngIf="selectedValue === 'knowledge'" class="value-wrapper">
        <section class="image-wrapper col-12">
          <img class="image-with-ellipse" src="assets/images/knowledge.svg">
        </section>

        <p class="value-title">{{"OUR_VALUES.VALUE_1" | translate}}</p>
        <p class="value-description">{{"OUR_VALUES.VALUE_1_DESCRIPTION" | translate}}</p>
        <img class="value-images col-12" src="assets/images/knowledge_pics.webp">

        <div class="next-wrapper" routerLink="/our-values/stunning-beauty" (click)="jumpTop()">
          <img class="" src="assets/images/arrow_right.svg" alt="">
          <p class="next-value">{{"OUR_VALUES.VALUE_2_" | translate}}</p>
        </div>
      </section>

      <!-- // Meghökkentő szépség-->

      <section *ngIf="selectedValue === 'stunning-beauty'" class="value-wrapper">
        <section class="image-wrapper col-12">
          <img class="image-with-ellipse" src="../../assets/images/beauty_icon.svg">
        </section>

        <p class="value-title">{{"OUR_VALUES.VALUE_2" | translate}}</p>
        <p class="value-description">{{"OUR_VALUES.VALUE_2_DESCRIPTION" | translate}}</p>
        <img class="value-images col-12" src="assets/images/stunning_beatuy_pics.webp">

        <div class="next-wrapper" routerLink="/our-values/visibility" (click)="jumpTop()">
          <img class="" src="assets/images/arrow_right.svg" alt="">
          <p class="next-value">{{"OUR_VALUES.VALUE_3_" | translate}}</p>
        </div>
      </section>
      <!--// Átláthatóság-->
      <section *ngIf="selectedValue === 'visibility'" class="value-wrapper">
        <section class="image-wrapper col-12">
          <img class="image-with-ellipse" src="../../assets/images/visibility.svg">
        </section>

        <p class="value-title">{{"OUR_VALUES.VALUE_3" | translate}}</p>
        <p class="value-description">{{"OUR_VALUES.VALUE_3_DESCRIPTION" | translate}}</p>
        <img class="value-images col-12" src="assets/images/visibility_pics.webp">

        <div class="next-wrapper" routerLink="/our-values/trust" (click)="jumpTop()">
          <img class="" src="assets/images/arrow_right.svg" alt="">
          <p class="next-value">{{"OUR_VALUES.VALUE_4_" | translate}}</p>
        </div>
      </section>

      <!-- // Őszinteség-->
      <section *ngIf="selectedValue === 'trust'" class="value-wrapper">
        <section class="image-wrapper col-12">
          <img class="image-with-ellipse" src="../../assets/images/trust_icon.svg">
        </section>

        <p class="value-title">{{"OUR_VALUES.VALUE_4" | translate}}</p>
        <p class="value-description">{{"OUR_VALUES.VALUE_4_DESCRIPTION" | translate}}</p>
        <img class="value-images col-12" src="assets/images/trust_pics.webp">

        <div class="next-wrapper" routerLink="/our-values/sustainability" (click)="jumpTop()">
          <img class="" src="assets/images/arrow_right.svg" alt="">
          <p class="next-value">{{"OUR_VALUES.VALUE_5_" | translate}}</p>
        </div>
      </section>
      <!--// Fenntarthatóság-->

      <section *ngIf="selectedValue === 'sustainability'" class="value-wrapper">
        <section class="image-wrapper col-12">
          <img class="image-with-ellipse" src="../../assets/images/sustainability_icon.svg">
        </section>

        <p class="value-title">{{"OUR_VALUES.VALUE_5" | translate}}</p>
        <p class="value-description">{{"OUR_VALUES.VALUE_5_DESCRIPTION" | translate}}</p>
        <img class="value-images col-12" src="assets/images/sustainability_pics.webp">

        <div class="next-wrapper" routerLink="/our-values/reality" (click)="jumpTop()">
          <img class="" src="assets/images/arrow_right.svg" alt="">
          <p class="next-value">{{"OUR_VALUES.VALUE_6_" | translate}}</p>
        </div>
      </section>
      <!--  // Valóság-->

      <section *ngIf="selectedValue === 'reality'" class="value-wrapper">
        <section class="image-wrapper col-12">
          <img class="image-with-ellipse" src="../../assets/images/reality_icon.svg">
        </section>

        <p class="value-title">{{"OUR_VALUES.VALUE_6" | translate}}</p>
        <p class="value-description">{{"OUR_VALUES.VALUE_6_DESCRIPTION" | translate}}</p>
        <img class="value-images col-12" src="assets/images/reality_pics.webp">

        <div class="next-wrapper" routerLink="/our-values/innovation" (click)="jumpTop()">
          <img class="" src="assets/images/arrow_right.svg" alt="">
          <p class="next-value">{{"OUR_VALUES.VALUE_7_" | translate}}</p>
        </div>
      </section>
      <!--  // Innováció-->

      <section *ngIf="selectedValue === 'innovation'" class="value-wrapper">
        <section class="image-wrapper col-12">
          <img class="image-with-ellipse" src="../../assets/images/innovation_icon.svg">
        </section>

        <p class="value-title">{{"OUR_VALUES.VALUE_7" | translate}}</p>
        <p class="value-description">{{"OUR_VALUES.VALUE_7_DESCRIPTION" | translate}}</p>
        <img class="value-images col-12" src="assets/images/innovation_pics.webp">

        <div class="next-wrapper" routerLink="/our-values/safety" (click)="jumpTop()">
          <img class="" src="assets/images/arrow_right.svg" alt="">
          <p class="next-value">{{"OUR_VALUES.VALUE_8_" | translate}}</p>
        </div>
      </section>

      <!--  // Biztonság-->
      <!---->
      <section *ngIf="selectedValue === 'safety'" class="value-wrapper">
        <section class="image-wrapper col-12">
          <img class="image-with-ellipse" src="../../assets/images/safety_icon.svg">
        </section>

        <p class="value-title">{{"OUR_VALUES.VALUE_8" | translate}}</p>
        <p class="value-description">{{"OUR_VALUES.VALUE_8_DESCRIPTION" | translate}}</p>
        <img class="value-images col-12" src="assets/images/safety_pics.webp">

        <div class="next-wrapper" routerLink="/our-values/knowledge" (click)="jumpTop()">
          <img class="" src="assets/images/arrow_right.svg" alt="">
          <p class="next-value">{{"OUR_VALUES.VALUE_1_" | translate}}</p>
        </div>
      </section>


    </section>
  </section>
</section>
