import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-simplepay-error',
  templateUrl: './simplepay-error.component.html',
  styleUrls: ['./simplepay-error.component.css', '../error/error.component.css']
})
export class SimplepayErrorComponent implements OnInit {

  errorCodes: string;
  orderId: string;

  constructor(
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    const urlParams = new URLSearchParams(window.location.search);

    this.errorCodes = urlParams.get('codes').split('-').join(', ');
    this.orderId = this.route.snapshot.paramMap.get('id');

  }

}
