<section class="selected-category-wrapper">

  <section class="categories">
    <section class="container">
      <section class="row" id="404">
        <h1 style="font-size: 150px">404</h1>
        <p>Sajnáljuk, ez az oldal nem található.</p>
      </section>
    </section>
  </section>
</section>
