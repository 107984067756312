import {Component, OnInit} from '@angular/core';
import {AppService} from '../app.service';
import {PersistanceService} from '../persistance.service';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {

  counter = 0;

  changer = [
    {img: '', counter: 1, title: 'LANDING.HAIR_TITLE', lead: 'LANDING.HAIR_LEAD', description: 'LANDING.HAIR_DESCRIPTION'},
    {img: '', counter: 2, title: 'LANDING.FACE_TITLE', lead: 'LANDING.FACE_LEAD', description: 'LANDING.FACE_DESCRIPTION'},
    {img: '', counter: 3, title: 'LANDING.SKIN_TITLE', lead: 'LANDING.SKIN_LEAD', description: 'LANDING.SKIN_DESCRIPTION'},
    {img: '', counter: 4, title: 'LANDING.BELLY_TITLE', lead: 'LANDING.BELLY_LEAD', description: 'LANDING.BELLY_DESCRIPTION'}
  ];

  agents = [
    {
      name: 'LANDING.AGENT_1',
      effect: 'LANDING.AGENT_1_EFFECT'
    },
    {
      name: 'LANDING.AGENT_2',
      effect: 'LANDING.AGENT_2_EFFECT'
    },
    {
      name: 'LANDING.AGENT_3',
      effect: 'LANDING.AGENT_3_EFFECT'
    },
    {
      name: 'LANDING.AGENT_4',
      effect: 'LANDING.AGENT_4_EFFECT'
    },
    {
      name: 'LANDING.AGENT_5',
      effect: 'LANDING.AGENT_5_EFFECT'
    }
  ];

  constructor(
    private service: AppService,
    private translate: TranslateService,
    private persistance: PersistanceService
  ) {
  }

  ngOnInit(): void {
  }

  changeCounter(method): void {
    let tempCounter = this.counter;
    if (method === 'dec') {
      tempCounter = this.counter - 1;
      if (tempCounter === -1) {
        this.counter = this.changer.length - 1;
      } else {
        this.counter = tempCounter;
      }
    } else if (method === 'inc') {
      tempCounter = this.counter + 1;
      if (tempCounter >= this.changer.length) {
        this.counter = 0;
      } else {
        this.counter = tempCounter;
      }
    }
  }

  getMagicOil(): void {
    if (!this.persistance.get('api_token') || this.persistance.get('api_token') === 'undefined') {
      this.service.guestToken().subscribe(tokenResponse => {
        this.persistance.set('api_token', tokenResponse.data.api_token);
        this.addToCart();
      });
    } else {
      this.addToCart();
    }
  }

  addToCart(): void {
    this.service.addCart(56, 0, 0).subscribe(response => {
      const fbImage = document.createElement('img');
      fbImage.style.display = 'none';
      fbImage.style.width = '1px';
      fbImage.style.height  = '1px';
      fbImage.src = 'https://www.facebook.com/tr?id=265138295569123&ev=AddToCart&cd[currency]=HUF&cd[value]=1.00&time=' + Date.now();
      const element = document.getElementById('facebook-add-to-cart');
      element.appendChild(fbImage);
      const anjaAlert = Swal.mixin({
        customClass: {
          title: 'anja-alert-title',
          confirmButton: 'anja-alert-button',
        }
      });

      anjaAlert.fire({
        icon: 'success',
        title: this.translate.instant('BASKET.ADDED_TO_BASKET_3'),
        toast: true,
        timerProgressBar: true,
        timer: 3000,
        position: 'center',
        focusConfirm: false,
        showConfirmButton: true,
        confirmButtonColor: '#3D3D3D',
        iconColor: '#0bbf74a6',
        confirmButtonText: this.translate.instant('LOGIN.OKAY'),
      });
    }, error => {
      this.service.refreshToken(this.persistance.get('logged_in_user'));
    });
  }

}
