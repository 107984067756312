<form [formGroup]="subscribeForm">
<section id="footer" class="footer-section">
  <section class="container">
    <section class="row justify-content-center">
      <section class="col-12 col-md-4 col-lg-3">
        <img class="logo" (click)="mainPage.scrollToTop()" src="../../assets/images/top-logo.svg">
        <p class="below-logo">{{"FOOTER.CODE_GLOBAL" | translate}}</p>
        <p class="below-logo">{{"FOOTER.ALL_RIGHTS_RESERVED" | translate}}</p>
      </section>

      <section class="col-12 col-md-4 col-lg-2">
        <p class="header">{{"FOOTER.LAW_DOCS" | translate}}</p>
<!--        <p class="col-elem">{{"FOOTER.FAQ" | translate}}</p>-->
        <p routerLink="/terms" class="col-elem">{{"FOOTER.ASZF" | translate}}</p>
        <p routerLink="/policy" class="col-elem">{{"FOOTER.POLICY" | translate}}</p>
        <p routerLink="/cookie-policy" class="col-elem">{{"FOOTER.COOKIE" | translate}}</p>
      </section>

      <section class="col-12 col-md-4 col-lg-2">
        <p class="header">{{"FOOTER.STORY" | translate}}</p>
        <p routerLink="/our-mission" class="col-elem">{{"FOOTER.OUR_MISSION" | translate}}</p>
        <p routerLink="/blog" class="col-elem">{{"FOOTER.BLOG" | translate}}</p>
        <p routerLink="/contact" class="col-elem">{{"FOOTER.CONTACT_2"  | translate}}</p>
      </section>

      <section class="subscribe-section col-12 col-md-12 col-lg-5">
        <section class="row">
          <p class="header subscribe-header col-12 col-md-6">{{"FOOTER.SUBSCRIBE" | translate}}</p>
          <p class="jump-top jump-top-desktop col-md-6" (click)="jumpTop()">
            <img class="mini-triangle" src="../../assets/images/mini_triangle.svg">
            {{"FOOTER.JUMP_TOP" | translate}}
          </p>

          <input class="input-field left-input col-12 col-md-6" type="text" formControlName="name"
                 placeholder="{{'SUBSCRIBE.NAME_PLACEHOLDER' | translate}}">
          <input class="input-field right-input col-12 col-md-6" type="text" formControlName="email"
                 placeholder="{{'SUBSCRIBE.EMAIL_PLACEHOLDER' | translate}}">
        </section>

        <section class="radio-wrapper">
          <input type="radio" id="radio-policy-footer" formControlName="policyChecked"
                 (click)="togglePolicyButton()">
          <label for="radio-policy-footer">{{'SUBSCRIBE.THE' | translate}}<a class="link" routerLink="/policy"
                                                                             href="">{{'SUBSCRIBE.POLICY'
            | translate}}</a>{{'SUBSCRIBE.UNDERSTOOD_AND_ACCEPTED' | translate}}
          </label>
        </section>

        <section class="radio-wrapper">
          <input type="radio" id="radio-aszf-footer" formControlName="termsChecked"
                 (click)="toggleTermsButton()">
          <label for="radio-aszf-footer">{{'SUBSCRIBE.ACCEPT' | translate}}<a class="link" routerLink="/terms"
                                                                              href="">{{'SUBSCRIBE.ASZF' |
            translate}}</a></label>
        </section>

        <button (click)="subscribeMail()"
                class="button"
                [disabled]="isSubmitted || subscribeForm.status !== 'VALID' || !getRadioValues('radio-policy-footer')  || !getRadioValues('radio-aszf-footer')">
          <img *ngIf="isSubmitted" class="spinner" src="assets/images/loading.gif" alt="Loading Spinner">
          {{'BUTTON.SUBSCRIBE' | translate}}
        </button>

        <p class="jump-top jump-top-mobile" (click)="jumpTop()">
          <img class="mini-triangle" src="../../assets/images/mini_triangle.svg">
          {{"FOOTER.JUMP_TOP" | translate}}
        </p>
      </section>
    </section>
  </section>
</section>
</form>


