import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  constructor(private location: Location) {
  }

  ngOnInit(): void {
    this.jumpTop();
  }

  back(): void {
    this.location.back();
  }

  jumpTop(): void {
    // @ts-ignore
    window.scrollTo({top: 0, behavior: 'instant'});
  }
}
