<section class="login-section">
  <section class="container">
    <section class="row justify-content-center justify-content-lg-between">
      <section class="window col-12 col-lg-6">
        <section class="window-header">
          <p class="window-title">{{"REGISTRATION.REGISTRATION" | translate}}</p>
        </section>

        <section class="window-top">
          <form [formGroup]="registerForm">
            <p class="input-span">{{"REGISTRATION.FIRST_NAME" | translate}}</p>
            <input class="input-field" type="text" id="firstname" name="firstname"
                   formControlName="firstname" placeholder="{{'REGISTRATION.FIRST_NAME' | translate}}" required>

            <p class="input-span">{{"REGISTRATION.LAST_NAME" | translate}}</p>
            <input class="input-field" type="text" id="lastname" name="lastname"
                   formControlName="lastname" placeholder="{{'REGISTRATION.LAST_NAME' | translate}}" required>

            <p class="input-span">{{"LOGIN.EMAIL" | translate}}</p>
            <input class="input-field" type="email" id="email" name="email" email
                   formControlName="email" placeholder="{{'REGISTRATION.EMAIL' | translate}}" required>

            <p class="input-span">{{"LOGIN.PASSWORD" | translate}}</p>
            <input class="input-field" type="password" id="password" name="password"
                   formControlName="password" placeholder="{{'REGISTRATION.PASSWORD' | translate}}" required>
            <br>

            <p class="input-span">{{"REGISTRATION.CONFIRM" | translate}}</p>
            <input class="input-field" type="password" id="confirm" name="confirm"
                   formControlName="confirm" placeholder="{{'REGISTRATION.CONFIRM' | translate}}" required>
            <br>
            <section class="thin-line"></section>
            <section class="radio-wrapper">
              <input type="radio" id="radio-policy" formControlName="policyChecked" ngModel
                     (click)="togglePolicyButton()">
              <label for="radio-policy">{{'SUBSCRIBE.THE' | translate}}<a class="link"
                                                                          routerLink="/policy">{{'SUBSCRIBE.POLICY' |
                translate}}</a>{{'SUBSCRIBE.UNDERSTOOD_AND_ACCEPTED' | translate}}
              </label>
            </section>

            <section class="radio-wrapper">
              <input type="radio" id="radio-terms" formControlName="termsChecked" ngModel (click)="toggleTermsButton()">
              <label for="radio-terms">{{'SUBSCRIBE.ACCEPT' | translate}}<a class="link"
                                                                            routerLink="/terms">{{'SUBSCRIBE.ASZF' |
                translate}}</a></label>
            </section>

            <section class="radio-wrapper">
              <input type="radio" id="sub" formControlName="isSubbed" value=true
                     (click)="toggleSubButton()">
              <label class="radio-label"
                     for="sub">{{'PAYMENT.SUB_TO_MAIL'| translate}}</label>
            </section>

          </form>

          <p class="input-span">{{"SUBSCRIBE.LANGUAGE" | translate}}</p>
          <select id="lang-selector" class="input-field input-select" [(ngModel)]="selectedLanguage">
            <option *ngFor="let lang of service.lang"
                    [ngValue]="lang.code">{{ 'LANG.' + lang.code | translate}}</option>
          </select>

          <form [formGroup]="registerForm">

            <section id="button-container" class="row button-wrapper">
              <button (click)="register()"
                      class="button"
                      [disabled]="isSubmitted || registerForm.status !== 'VALID' || !getRadioValues('radio-policy')  || !getRadioValues('radio-terms')">
                <img *ngIf="isSubmitted" class="spinner" src="assets/images/loading.gif" alt="Loading Spinner">
                {{'REGISTRATION.REGISTRY' | translate}}

              </button>
              <p class="underlined col-12"></p>
            </section>
          </form>
        </section>

        <section class="window-bottom">
        </section>
      </section>

      <section class="fancy-image-section col-lg-6">
        <img class="login-image" src="assets/images/login_images_group.png" alt="">
      </section>
    </section>
  </section>


</section>

