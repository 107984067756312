import {Component, OnInit} from '@angular/core';
import {AppService} from '../app.service';
import {Router, ActivatedRoute} from '@angular/router';
import {PersistanceService} from '../persistance.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
})
export class MainComponent implements OnInit {

  lang = '';
  requestHomepageData: any;
  homepageData: any;
  tiles = [];
  blogs = [];

  constructor(
    private service: AppService,
    private router: Router,
    private route: ActivatedRoute,
    private persistence: PersistanceService
  ) {
  }

  ngOnInit(): void {
    this.lang = this.persistence.get('lang');
    this.route.params.subscribe(param => {
      if (param.subscribe === 'subscribe') {
        setTimeout(() => {
          document.getElementById('subscribe').scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
          }, 1000);
      }
    });

    if (window.innerWidth < 768) {
      /*      this.requestHomepageData = this.service.getPage('homepage&width=' + window.innerWidth + '&height=' + (window.innerWidth + 100));*/
      this.requestHomepageData = this.service.getPage('homepage');
      this.requestHomepageData.subscribe(data => {
        this.homepageData = data;

        Object.values(this.homepageData.data.tiles).forEach(tile => {
          this.tiles.push(tile);
        });

        Object.values(this.homepageData.data['blog-posts']).forEach(blog => {
          this.blogs.push(blog);
          // this.blogs = this.homapageData.data['blog-posts'];
        });
      });
    } else {
      /*      this.requestHomepageData = this.service.getPage('homepage&width=' + window.innerWidth + '&height=' + (window.innerWidth + 100));*/
      this.requestHomepageData = this.service.getPage('homepage');
      this.requestHomepageData.subscribe(data => {
        this.homepageData = data;

        Object.values(this.homepageData.data.tiles).forEach(tile => {
          this.tiles.push(tile);
        });

        Object.values(this.homepageData.data['blog-posts']).forEach(blog => {
          this.blogs.push(blog);
        });
      });
    }
  }

  getType(type): string {
    return type;
  }

  // ToDo: remove this after the webp not-webp image shower is done
  getImageType(original): string {
    return 'image/' + original.split('.').pop();
  }

  scrollToTop(): void {
    this.router.navigate(['/']);
    setTimeout(() => {
      // @ts-ignore
      window.scrollTo({top: 0, behavior: 'instant'});
    }, 300);
  }

  scrollToValues(): void {
    this.router.navigate(['/']);
    setTimeout(() => {
      const element = document.getElementById('value-jumper');
      // @ts-ignore
      element.scrollIntoView({behavior: 'instant', block: 'center', inline: 'start'});
    }, 300);
  }

}
