<!--<head>-->
<!--  &lt;!&ndash; Global site tag (gtag.js) - Google Ads: 10837993304 &ndash;&gt;-->
<!--  <script async src="https://www.googletagmanager.com/gtag/js?id=AW-10837993304"></script>-->
<!--  <script> window.dataLayer = window.dataLayer || [];-->

<!--  function gtag() {-->
<!--    dataLayer.push(arguments);-->
<!--  }-->

<!--  gtag('js', new Date());-->
<!--  gtag('config', 'AW-10837993304'); </script>-->
<!--</head>-->
<section class="image-tiles row">
  <section class="image-tile col-6 col-sm-6 col-md-3" *ngFor="let tile of this.tiles">
    <section *ngIf="getType(tile.type) === 'picture'">
      <picture>
        <img src="{{tile.pictures.webp}}">
        <!--        <img src="{{tile.pictures.original}}" alt="Alt Text!">-->
        <!--        <source [attr.lazyLoad]="tile.pictures.webp" src="{{tile.pictures.webp}}" srcset="{{tile.pictures.webp}}" type="image/webp">-->
        <!--        <source [attr.lazyLoad]="tile.pictures.original" srcset="{{tile.pictures.original}}"-->
        <!--                type="{{getImageType(tile.pictures.original)}}">-->
      </picture>
      <p class="text-on-picture" *ngIf="tile.text !== ' '">{{tile.text}}</p>
    </section>

    <section *ngIf="getType(tile.type) === 'category'">
      <picture>
        <img src="{{tile.pictures.webp}}">
        <!--        <img src="{{tile.pictures.original}}" alt="Alt Text!">-->
        <!--        <source [attr.lazyLoad]="tile.pictures.webp" srcset="{{tile.pictures.webp}}" type="image/webp">-->
        <!--        <source [attr.lazyLoad]="tile.pictures.original" srcset="{{tile.pictures.original}}"-->
        <!--                type="{{getImageType(tile.pictures.original)}}">-->
      </picture>
      <button routerLink="/category/{{tile.name}}" routerLinkActive="active-class"
              class="button blog-button">{{tile.name}}</button>
    </section>

    <section *ngIf="getType(tile.type) === 'product'">
      <picture>
        <img src="{{tile.pictures.webp}}">
        <!--        <img src="{{tile.pictures.original}}" alt="Alt Text!">-->
        <!--        <source [attr.lazyLoad]="tile.pictures.webp" srcset="{{tile.pictures.webp}}" type="image/webp">-->
        <!--        <source [attr.lazyLoad]="tile.pictures.original" srcset="{{tile.pictures.original}}"-->
        <!--                type="{{getImageType(tile.pictures.original)}}">-->
      </picture>
      <button routerLink="/product/{{tile.seo_url}}" routerLinkActive="active-class"
              class="button blog-button">{{tile.name}}</button>
    </section>

    <section *ngIf="getType(tile.type) === 'blog_post'">
      <picture>
        <img src="{{tile.pictures.webp}}">
        <!--        <img src="{{tile.pictures.original}}" alt="Alt Text!">-->
        <!--        <source [attr.lazyLoad]="tile.pictures.webp" srcset="{{tile.pictures.webp}}" type="image/webp">-->
        <!--        <source [attr.lazyLoad]="tile.pictures.original" srcset="{{tile.pictures.original}}"-->
        <!--                type="{{getImageType(tile.pictures.original)}}">-->
      </picture>
      <button class="button blog-button" routerLink="{{'/blog/' + tile.seo_url}}">{{'BUTTON.NEXT' | translate}}</button>

    </section>
  </section>
</section>

<section class="space-container">
  <section class="our-values-section">
    <section class="line"></section>
    <p class="title-large">{{'MAIN.OUR_VALUES' | translate}}</p>
    <p id="value-jumper" class="paragraph-text">{{'MAIN.OUR_VALUES_DESCRIPTION' | translate}}</p>

    <section id="value-img-container" class="container">
      <section id="value-container" class="row justify-content-center">
        <section class="value-wrapper col-6 col-md-3 order-md-0" routerLink="/our-values/knowledge">
          <img class="value" src="../../assets/images/value_1.svg">
          <img class="arrow arrow-bottom" src="../../assets/images/arrow.png">
          <p class="value-text value-text-bottom">{{'MAIN.VALUE_1' | translate}}</p>
        </section>

        <section class="value-wrapper col-6 col-md-3 order-md-0" routerLink="/our-values/stunning-beauty">
          <img class="value" src="../../assets/images/value_2.svg">
          <img class="arrow arrow-bottom" src="../../assets/images/arrow.png">
          <p class="value-text value-text-bottom long-value-text">{{'MAIN.VALUE_2' | translate}}</p>
        </section>

        <section class="value-wrapper col-6 col-md-3 order-md-2" routerLink="/our-values/sustainability">
          <img class="value" src="../../assets/images/value_5.svg">
          <img class="arrow arrow-upper" src="../../assets/images/arrow.png">
          <p class="value-text value-text-upper">{{'MAIN.VALUE_5' | translate}}</p>
        </section>

        <section class="value-wrapper col-6 col-md-3 order-md-2" routerLink="/our-values/reality">
          <img class="value" src="../../assets/images/value_6.svg">
          <img class="arrow arrow-upper" src="../../assets/images/arrow.png">
          <p class="value-text value-text-upper">{{'MAIN.VALUE_6' | translate}}</p>
        </section>

        <section class="value-wrapper col-6 col-md-3 order-md-0"  routerLink="/our-values/visibility">
          <img class="value" src="../../assets/images/value_3.svg">
          <img class="arrow arrow-bottom" src="../../assets/images/arrow.png">
          <p class="value-text value-text-bottom">{{'MAIN.VALUE_3' | translate}}</p>
        </section>

        <section class="value-wrapper col-6 col-md-3 order-md-0"  routerLink="/our-values/trust">
          <img class="value" src="../../assets/images/value_4.svg">
          <img class="arrow arrow-bottom" src="../../assets/images/arrow.png">
          <p class="value-text value-text-bottom">{{'MAIN.VALUE_4' | translate}}</p>
        </section>

        <section class="value-wrapper col-6 col-md-3 order-md-2" routerLink="/our-values/innovation">
          <img class="value" src="../../assets/images/value_7.svg">
          <img class="arrow arrow-upper" src="../../assets/images/arrow.png">
          <p class="value-text value-text-upper">{{'MAIN.VALUE_7' | translate}}</p>
        </section>

        <section class="value-wrapper col-6 col-md-3 order-md-2" routerLink="/our-values/safety">
          <img class="value" src="../../assets/images/value_8.svg">
          <img class="arrow arrow-upper" src="../../assets/images/arrow.png">
          <p class="value-text value-text-upper">{{'MAIN.VALUE_8' | translate}}</p>
        </section>
      </section>
    </section>

    <section id="text-section" class="container">
      <section class="row">
        <section class="thoughts-section col-12 col-md-8">
          <p class="title-small">{{'MAIN.KNOW_US' | translate}}</p>
          <p class="title-large title-large-left-align">{{'MAIN.THOUGHTS_ABOUT_ANJA' | translate}}</p>
          <p class="thoughts">{{'MAIN.THOUGHTS' | translate}}</p>
          <section class="col-12">
            <img class="anja-signature-image" src="assets/images/anja_signature.svg">
          </section>

          <button routerLink="/our-mission" class="button left-align-button">{{'BUTTON.NEXT' | translate}}</button>
        </section>

        <section class="images-section col-12 col-md-6">
          <img class="woman-flower" src="../../assets/images/woman_flower.webp">
        </section>
      </section>
      <img *ngIf="lang === 'hu-hu'" class="stamp col-12" src="assets/images/szlogen.svg" alt="szlogen">
      <img *ngIf="lang === 'en-gb'" class="stamp col-12" src="assets/images/szlogen_en.svg" alt="motto">
    </section>
    <img class="woman-flower-larger" src="../../assets/images/woman_flower.webp">
  </section>


  <app-latest-blogs></app-latest-blogs>
  <app-subscribe id="subscribe"></app-subscribe>
</section>
