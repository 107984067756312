<section class="full-navbar-container">
  <section *ngIf="topBarData" class="top-bar-container">
    <span class="info" *ngIf="!isTopNotificationUrlValid(topBarData.url)">{{topBarData.title}}</span>
    <a class="link" *ngIf="isTopNotificationUrlValid(topBarData.url)" href="{{topBarData.url}}"> {{topBarData.title}}</a>
  </section>

  <nav class="navbar navbar-light" aria-label="First navbar example">
    <div class="container-fluid">
      <section class="anja-logo-wrapper">
        <img class="anja-logo" routerLink="" src="assets/images/top-logo.svg">
      </section>
      <div class="basket-in-nav-mobile">
        <span *ngIf="service.itemsInBasket !== 0" class="items-in-basket"
              (click)="service.showBasket = !service.showBasket">{{service.itemsInBasket}}</span>
        <p class="svg-icon shopping-cart nav-img"
           (click)="service.showBasket = !service.showBasket"></p>
      </div>

      <button class="navbar-toggler custom-toggler collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarsExample01" aria-controls="navbarsExample01" aria-expanded="false"
              aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>


      <div class="navbar-collapse collapse" id="navbarsExample01" style="">
        <ul class="navbar-nav me-auto mb-2">
          <li class="nav-item">
            <a *ngFor="let menuitem of menuItems" class="nav-link" routerLink="/category/{{menuitem.name}}"
               routerLinkActive="active-class" (click)="setCategoryData(menuitem)"> {{menuitem.name}}</a>
          </li>
          <!--          <li class="nav-item">-->
          <!--            <a class="nav-link" routerLinkActive="active-class" routerLink="/products">{{'MENU.MENU_5' | translate}}</a>-->
          <!--          </li>-->
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active-class"
               routerLink="/magic-oil">{{'MENU.MENU_7' | translate}}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active-class" routerLink="/blog">{{'MENU.MENU_6' | translate}}</a>
          </li>

          <li class="nav-item" *ngIf="false" >
<!--          <li class="nav-item">-->
            <p class="lang-select " (click)="showLangs = !showLangs">{{currentLang}}</p>
            <div *ngIf="showLangs">
              <div *ngFor="let lang of langs">
                <p class="lang-select lang-select-elem" (click)="setLang(lang.code, lang.lang)">{{lang.lang}}</p>
              </div>
            </div>
          </li>

          <li class="nav-item">
            <p (click)="service.showBasket = !service.showBasket" class="shopping-cart mobile-nav-img nav-link"></p>
            <span *ngIf="service.itemsInBasket !== 0" class="items-in-basket"
                  (click)="service.showBasket = !service.showBasket">{{service.itemsInBasket}}</span>
          </li>
          <li class="nav-item">
            <a class="profile mobile-nav-img nav-link" routerLink="/login"></a>
          </li>
        </ul>
      </div>
    </div>
  </nav>


  <section class="desktop-navbar container">
    <section class="row justify-content-evenly">
      <section class="anja-logo-wrapper col-2">
        <img class="anja-logo" routerLink="" src="assets/images/top-logo.svg">
      </section>

      <section class="col-7 row">
        <a *ngFor="let menuitem of menuItems" class="nav-link" routerLink="/category/{{menuitem.name}}"
           routerLinkActive="active-class" (click)="setCategoryData(menuitem)">{{menuitem.name}}</a>
        <!--        <a class="nav-link" routerLink="/products" routerLinkActive="active-class">{{'MENU.MENU_5' | translate}}</a>-->
        <a class="nav-link" routerLinkActive="active-class" routerLink="/magic-oil">{{'MENU.MENU_7' | translate}}</a>
        <a class="nav-link" routerLinkActive="active-class" routerLink="/blog">{{'MENU.MENU_6' | translate}}</a>
      </section>

      <p *ngIf="false" class="lang-select" (click)="showLangs = !showLangs">{{currentLang}}</p>
<!--      <p class="lang-select" (click)="showLangs = !showLangs">{{currentLang}}</p>-->

      <div class="lang-select-window" *ngIf="showLangs">
        <div *ngFor="let lang of langs">
          <p class="lang-select lang-select-elem" (click)="setLang(lang.code, lang.lang)">{{lang.lang}}</p>
        </div>
      </div>

      <section class="col-2 row justify-content-end icon-holders">
        <p *ngIf="!onPaymentPage" class="svg-icon shopping-cart nav-img"
           (click)="service.showBasket = !service.showBasket"></p>
        <span *ngIf="service.itemsInBasket !== 0 && !onPaymentPage" class="items-in-basket"
              (click)="service.showBasket = !service.showBasket">{{service.itemsInBasket}}</span>
        <a class="svg-icon profile nav-img" routerLink="/login"></a>
      </section>
    </section>
  </section>
</section>

<div id="comp-render" *ngIf="service.showBasket || onPaymentPage">
  <app-basket></app-basket>
</div>

<div *ngIf="!cookieAccepted && !service.cookiesRejectedOnce" class="row justify-content-xl-between snackbar">
  <p class="cookie-text col-12 col-lg-12 col-xl-9">{{'MENU.COOKIE_TEXT_1' | translate}}
    <a class="cookie-text cookie-policy"
       routerLink="/cookie-policy">{{'MENU.COOKIE_TEXT_2' | translate}}</a>{{'MENU.COOKIE_TEXT_3' | translate}}
  </p>
  <p class="cookie-buttons col-1" (click)="setCookie(true)">{{'MENU.ACCEPT' | translate}}</p>
  <p class="cookie-buttons col-1" (click)="setCookie(false)">{{'MENU.REJECT' | translate}}</p>
</div>

<div *ngIf="service.cookieWarning" class="row justify-content-xl-between snackbar">
  <p class="cookie-text cookie-text-warning col-12 col-lg-12 col-xl-9">{{'MENU.COOKIE_TEXT_4' | translate}}
    <a class="cookie-text cookie-policy" routerLink="/cookie-policy"> {{'MENU.COOKIE_TEXT_5' | translate}}</a>
  </p>
  <p class="cookie-buttons col-1" (click)="setCookieAfterWarning()">{{'MENU.ACCEPT' | translate}}</p>
</div>

