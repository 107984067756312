<section class="blog-container-section container">
  <section class="line"></section>
  <p class="title-large">{{'BLOGS.THIS_IS_ANJA_BLOG' | translate}}</p>
  <p class="paragraph-text">{{'BLOGS.BLOG_DESCRIPTION' | translate}}</p>

  <section class="blogs-container container">
    <section class="row justify-content-center justify-content-md-start">
      <section class="col-12 col-md-6 col-lg-3" *ngFor="let blog of blogs; let i = index">
        <picture>
          <!--          <source [attr.lazyLoad]="blog.images.webp" class="blog-pic blog-pic-{{i}}"-->
          <!--                  srcset="{{blog && blog.images.webp}}" type="image/webp">-->
          <!--          <source [attr.lazyLoad]="blog.images.webp" class="blog-pic blog-pic-{{i}}"-->
          <!--                  srcset="{{blog && blog.images.original}}"-->
          <!--                  type="{{getImageType(blog.images.original)}}">-->
          <img class="blog-pic blog-pic-{{setBlogClassByIndex(i)}}" routerLink="/blog/{{blog['seo_url']}}" src="{{blog.images.webp}}">
        </picture>

        <p class="blog-title" routerLink="/blog/{{blog['seo_url']}}">{{blog && blog.title}}</p>
        <div class="blog-lead" routerLink="/blog/{{blog['seo_url']}}">
          <ngx-dynamic-hooks [content]="blog && blog.lead"></ngx-dynamic-hooks>
        </div>

        <button class="button under-blog-button" routerLink="/blog/{{blog['seo_url']}}">{{"BUTTON.READ_MORE" | translate}}</button>
      </section>
    </section>
  </section>

</section>

